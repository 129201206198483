/* eslint-disable no-nested-ternary */
import { Button, ButtonProps } from "@mui/material";

const AppButton = (props: ButtonProps & { btnicon?: boolean }) => {
  return (
    <Button
      // className={`btn btn_${props.color || "btn btn_primary"}`}
      className={`btn ${props?.btnicon ? "btnicon" : ""} ${
        props.variant === "contained"
          ? `btn_${props.color ?? "primary"}`
          : props.variant === "text"
            ? `btn_text_${props.color ?? "primary"}`
            : props.variant === "outlined"
              ? `btn_outlined_${props.color ?? "primary"}`
              : `btn_primary`
      }`}
      disabled={props.disabled}
      {...props}
      disableRipple={props.variant === "text"}
    />
  );
};
export default AppButton;
