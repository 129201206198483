import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../scss/common/common.module.scss";
import AppContainer from "../../common/theme/Container";
import AppButton from "../../common/theme/Button";
import { Decrypt, Encrypt } from "../../common/utils/Functions";

interface IProps {
  bundleDataList: any;
  productList: any;
  saveSubBundleOptionList: any;
  saveProduct: (productId: string, options: string, value: any) => void;
  saveOptopnList: any;
  saveProductChange: boolean;
  checkAll: boolean;
  isHide: boolean;
}

const BundleList = ({
  bundleDataList,
  saveSubBundleOptionList,
  productList,
  saveProduct,
  saveOptopnList,
  saveProductChange,
  isHide,
}: // checkAll,
IProps) => {
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const navigate = useNavigate();

  const saveOptionListBoth = [...saveOptopnList, ...saveSubBundleOptionList];
  const saveToCart = () => {
    const bundleData: any = bundleDataList ?? [];
    const subBundleData: any = [];
    bundleData?.sub_bundle?.map((bundleProducts: any) => {
      bundleProducts?.bundle_products?.map((product: any) => {
        const isExist = saveSubBundleOptionList.findIndex(
          (item: any) => item.productId === product.id
        );
        if (isExist !== -1) {
          subBundleData.push(product);
        }
      });
    });
    const bundleProductsList = bundleData?.bundle_products ?? [];
    bundleData.bundle_products = [...bundleProductsList, ...subBundleData];
    saveOptionListBoth?.map((productSave) => {
      let productData = bundleData.bundle_products.find(
        (product: any) => product.id === productSave.productId
      );

      if (productData && productData.store_product_detail) {
        // Create a deep copy of the productData object
        let productDataCopy = {
          ...productData,
          store_product_detail: {
            ...productData.store_product_detail,
            store_options: [...productData.store_product_detail.store_options],
          },
        };

        const index =
          productDataCopy.store_product_detail.store_options.findIndex(
            (option: any) => productSave.optionsId === option.id
          );

        if (productDataCopy.store_product_detail.store_options[index]) {
          productDataCopy.store_product_detail.store_options[index] = {
            ...productDataCopy.store_product_detail.store_options[index],
            selectedData: productSave.value,
          };
        }

        if (productSave.optionsId === "quantity") {
          productDataCopy.store_product_detail.quantity = parseInt(
            productSave.value
          );
        }

        // Here you would assign productDataCopy back to the appropriate place if necessary
        // For example, if you need to update the bundleData, you might do something like this:
        const productIndex = bundleData.bundle_products.findIndex(
          (product: any) => product.id === productSave.productId
        );
        if (productIndex !== -1) {
          bundleData.bundle_products[productIndex] = productDataCopy;
        }
      }
    });
    // bundleData.totalPrice = totalProduct;

    let newCartData: any = [];
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

    if (cartOldData && cartOldData.length > 0) {
      newCartData = cartOldData;
      const cartItemIndex = newCartData.findIndex(
        (cartProduct: any) =>
          cartProduct.id === bundleData.id && !cartProduct.isSingleProduct
      );

      if (cartItemIndex >= 0) {
        const cartBundle = newCartData[cartItemIndex];

        let newItem = false;
        let sameItem = "";

        bundleData.bundle_products.map((bundleItem: any, index: any) => {
          let isUpdate = false;

          const singleCartProducts = cartBundle.bundle_products.filter(
            (cartProduct: any) => {
              return cartProduct.id === bundleItem.id;
            }
          );

          if (singleCartProducts.length > 0) {
            singleCartProducts.map((cartSingleProduct: any) => {
              const findSameOption: any = [];
              bundleItem.store_product_detail.store_options.map(
                (storeOption: any) => {
                  const findData =
                    cartSingleProduct.store_product_detail.store_options.filter(
                      (cartOption: any) => {
                        return (
                          storeOption.global_option_id ===
                            cartOption.global_option_id &&
                          storeOption.selectedData === cartOption.selectedData
                        );
                      }
                    );

                  if (findData.length > 0) {
                    findSameOption.push(findData);
                  }
                }
              );

              if (
                findSameOption.length <
                bundleItem.store_product_detail.store_options.length
              ) {
                if (!isUpdate) {
                  newItem = true;
                  // const newBundleProductList =
                  //   newCartData[cartItemIndex].bundle_products;
                  // newBundleProductList.push({ ...bundleItem, cartItemId: 0 });

                  // newCartData[cartItemIndex].bundle_products =
                  //   newBundleProductList;

                  // const cartData = JSON.stringify(newCartData);
                  // const cartDataEn = Encrypt(cartData);
                  // localStorage.setItem("cart", cartDataEn);
                }
              } else {
                newItem = false;
                sameItem = cartSingleProduct.cartItemId;
                isUpdate = true;
              }
            });
          }
          if (newItem) {
            const newBundleProductList =
              newCartData[cartItemIndex].bundle_products;
            const maxCartItem = Math.max(
              ...cartBundle.bundle_products.map((o: any) =>
                Number(o.cartItemId)
              ),
              0
            );
            newBundleProductList.push({
              ...bundleItem,
              cartItemId: maxCartItem + 1,
            });
            newCartData[cartItemIndex].bundle_products = newBundleProductList;
            const cartData = JSON.stringify(newCartData);
            const cartDataEn = Encrypt(cartData);
            localStorage.setItem("cart", cartDataEn);
          } else {
            const itemIndex = cartBundle.bundle_products.findIndex(
              (item: any) => item.cartItemId === sameItem
            );

            let oldQty =
              cartBundle.bundle_products[itemIndex].store_product_detail
                .quantity;
            cartBundle.bundle_products[
              itemIndex
            ].store_product_detail.quantity =
              Number(oldQty) + Number(bundleItem.store_product_detail.quantity);

            newCartData[cartItemIndex] = cartBundle;

            // newCartData[itemIndex].bundle_products.store_product_detail.quantity =
            //   oldQty + bundleItem.store_product_detail.quantity;

            const cartData = JSON.stringify(newCartData);
            const cartDataEn = Encrypt(cartData);
            localStorage.setItem("cart", cartDataEn);
          }
        });

        // newCartData[cartItemIndex] = bundleData;
        // work hare
      } else {
        const newBundleProduct: any = [];
        bundleData.bundle_products.map((product: any, index: any) => {
          newBundleProduct.push({ ...product, cartItemId: index + 1 });
        });

        newCartData.push({ ...bundleData, bundle_products: newBundleProduct });
        const cartData = JSON.stringify(newCartData);
        const cartDataEn = Encrypt(cartData);
        localStorage.setItem("cart", cartDataEn);
      }
    } else {
      const cartBundleData = [];
      const newBundleProduct: any = [];
      bundleData.bundle_products.map((product: any, index: any) => {
        newBundleProduct.push({ ...product, cartItemId: index + 1 });
      });

      cartBundleData.push({ ...bundleData, bundle_products: newBundleProduct });
      const cartData = JSON.stringify(cartBundleData);

      const cartDataEn = Encrypt(cartData);
      localStorage.setItem("cart", cartDataEn);
    }

    navigate("/cart");
  };

  useEffect(() => {
    const data = saveOptionListBoth.filter((product: any) => {
      return product.isChecked === false;
    });

    if (data.length > 0) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
  }, [saveOptionListBoth, saveProductChange]);

  useEffect(() => {
    let total = 0;
    if (saveOptionListBoth && saveOptionListBoth.length > 0) {
      saveOptionListBoth.map((store_product: any) => {
        if (store_product.price) {
          total += store_product.price * store_product.value;
        }
      });

      setTotalProductPrice(total);
    }
  }, [saveOptionListBoth, saveProductChange]);

  return (
    <section className={styles.bundle_listMain}>
      {checkAll && !isHide && (
        <AppContainer>
          <div className={styles.bundle_list}>
            <div className={styles.bundle_listCard}>
              <div className={styles.bundle_listCardContent}>
                <h5>Bundle Subtotal</h5>
                <p>Plus shipping and taxes</p>

                <h3>{`$${parseFloat(totalProductPrice.toString()).toFixed(
                  2
                )}`}</h3>
              </div>
              <div className={styles.bundle_listCardlist}>
                <AppButton
                  onClick={saveToCart}
                  disabled={!checkAll}
                  style={{ cursor: checkAll ? "pointer" : "default" }}
                >
                  Add Bundle to Cart
                </AppButton>
              </div>
            </div>
          </div>
        </AppContainer>
      )}
    </section>
  );
};

export default BundleList;
