import { Link } from "react-router-dom";
import styles from "../../scss/common/common.module.scss";
import { InstagramIcon, RenAthIcon } from "../../assets/Icons/icons";
import Browser from "../../assets/Icons/Browser.svg";
import { IMAGE_BASE_URL } from "../../constants/constants";
import React from "react";
import AppContainer from "../theme/Container";

interface IInputProps {
  logo: string | undefined | null;
  data: any;
}
const FooterSection = ({ logo, data }: IInputProps) => {
  return (
    <>
      <section className={styles.contactUs_section}>
        <AppContainer>
          <div className="heading">
            <h2>
              Learn More About <b>REN Athletics</b>
            </h2>
          </div>
          <div className={styles.visitLinks}>
            <div className={styles.singleLink}>
              <h4>Follow Us</h4>
              <span className={styles.icon}>
                <Link
                  to="https://www.instagram.com/renathletics/"
                  target="_blank"
                >
                  {InstagramIcon}
                </Link>
              </span>
            </div>
            <div className={styles.singleLink}>
              <h4>Visit Us</h4>
              <span className={styles.icon}>
                <Link to="https://renathletics.com/" target="_blank">
                  {/* {globeIcon} */}
                  <img loading="lazy" src={Browser} alt="" />
                </Link>
              </span>
            </div>
          </div>
        </AppContainer>
      </section>
      <section
        className={styles.footer_section}
        style={{
          backgroundColor:
            data?.is_color_status === 2 ? data?.secondary_color ?? "" : "",
        }}
      >
        <AppContainer>
          <div
            className={styles.footer_wrapper}
            style={{
              color:
                data?.is_color_status === 2 ? data?.primary_color ?? "" : "",
            }}
          >
            <div className={styles.logo}>
              {logo !== "" && logo !== undefined && logo !== null ? (
                <img
                  loading="lazy"
                  src={`${IMAGE_BASE_URL}${logo}`}
                  alt="img"
                />
              ) : (
                <img loading="lazy" src="/images/logo.svg" alt="img" />
              )}
            </div>
            <div className={styles.copyright}>
              <b>The REN Hub</b>
              <h2>&#183;</h2>
              <p className={styles.footerP}>Powered by {RenAthIcon}</p>
            </div>
            <div className={styles.footerLinks}>
              <Link to="#">Privacy Policy</Link>
              <span>|</span>
              <Link to="#">Terms of Service</Link>
            </div>
          </div>
        </AppContainer>
      </section>
    </>
  );
};

export default FooterSection;
