import * as yup from "yup";

export const customerInformationForm = yup.object().shape({
  // firstName: yup
  //   .string().when("showFullname", {
  //     is: true,
  //     then: yup.string().required("Must enter email address")
  //   })
  //   .trim()
  //   .max(20, "Maximum 20 characters")
  //   .required("Please enter your first name"),

  firstName: yup.string().when("showFullname", {
    is: 1,
    then: (schema) =>
      schema
        .trim()
        .max(20, "Maximum 20 characters")
        .required("Please enter your first name"),
    otherwise: (schema) => schema,
  }),
  lastName: yup.string().when("showFullname", {
    is: 1,
    then: (schema) =>
      schema
        .trim()
        .max(20, "Maximum 20 characters")
        .required("Please enter your first name"),
    otherwise: (schema) => schema,
  }),
  email: yup
    .string()
    .trim()
    .email("Invalid email")
    .required("Please enter your email"),
  phone: yup
    .string()
    .trim()
    .min(7, "Please enter minimum 7 digit  ")
    .max(15, "Maximum 15 characters")
    .required("Please enter your first name"),
  BuildSide: yup.string().required("Please enter your first name"),
  dynamic: yup.string().when("showFullname", {
    is: true,
    then: (schema) => schema.required("This field is required"),
    otherwise: (schema) => schema,
  }),
});

export const paymentForm = yup.object().shape({
  // Card: yup.string().when("paymentType", {
  //   is: true,
  //   then: (schema) =>
  //     schema.trim().max(16).required("Please enter your credit card number"),
  //   otherwise: (schema) => schema,
  // }),

  // expiry: yup.string().when("paymentType", {
  //   is: true,
  //   then: (schema) => schema.trim().required("Please enter your expiry detail"),
  //   otherwise: (schema) => schema,
  // }),

  // cvv: yup.string().when("paymentType", {
  //   is: true,
  //   then: (schema) =>
  //     schema.trim().min(3).max(4).required("Please enter your cvv"),
  //   otherwise: (schema) => schema,
  // }),

  firstName: yup.string().when("checkbox", {
    is: false,
    then: (schema) =>
      schema
        .trim()
        .max(20, "Maximum 20 characters")
        .required("Please enter your first name"),
    otherwise: (schema) => schema,
  }),

  lastName: yup.string().when("checkbox", {
    is: false,
    then: (schema) =>
      schema
        .trim()
        .max(20, "Maximum 20 characters")
        .required("Please enter your last name"),
    otherwise: (schema) => schema,
  }),
  state: yup.string().when("checkbox", {
    is: false,
    then: (schema) => schema.trim().required("Please select state"),
    otherwise: (schema) => schema,
  }),
  aptUnit: yup.string().when("checkbox", {
    is: false,
    then: (schema) => schema.trim(),
    otherwise: (schema) => schema,
  }),
  street: yup.string().when("checkbox", {
    is: false,
    then: (schema) => schema.trim().required("Please enter the street"),
    otherwise: (schema) => schema,
  }),

  // state: yup.string().trim().required("Please enterstate"),
  // aptUnit: yup.string().trim().required("Please enter the Unit"),
  // street: yup.string().trim().required("Please enter the street"),

  zipCode: yup.string().when("checkbox", {
    is: false,
    then: (schema) =>
      schema
        .trim()
        .matches(/^[0-9]+$/, "Zip code must only contain numbers")
        .min(4, "Zip code can't be less than 4 characters")
        .max(10, "Zip code can't be more than 10  characters")
        .required("Please enter your zip code"),
    otherwise: (schema) => schema,
  }),
  city: yup.string().when("checkbox", {
    is: false,
    then: (schema) =>
      schema
        .trim()
        .matches(/^[A-Za-z ]+$/, "City must only contain alphabet")
        .max(30, "Maximum 30 characters are allowed")
        .required("Please enter your city"),
    otherwise: (schema) => schema,
  }),

  // zipCode: yup
  //   .string()
  //   .trim()
  //   .matches(/^[0-9]+$/, "Zip code must only contain numbers")
  //   .min(4, "Zip code can't be less than 4 characters")
  //   .max(10, "Zip code can't be more than 10  characters")
  //   .required("Please enter your zip code"),

  // city: yup
  //   .string()
  //   .trim()
  //   .max(30, "Maximum 30 characters are allowed")
  //   .required("Please enter your city"),
  // country: yup.string().required("Please enter your county"),
});

export const shippingMethodForm = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .max(20, "Maximum 20 characters")
    .required("Please enter your first name"),
  lastName: yup
    .string()
    .trim()
    .max(20, "Maximum 20 characters")
    .required("Please enter your last name"),
  state: yup.string().trim().required("Please select state"),
  aptUnit: yup.string().trim(),
  street: yup.string().trim().required("Please enter the street"),
  zipCode: yup
    .string()
    .trim()
    .matches(/^[0-9]+$/, "Zip code must only contain numbers")
    .min(4, "Zip code can't be less than 4 characters")
    .max(10, "Zip code can't be more than 10  characters")
    .required("Please enter your zip code"),

  city: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]+$/, "City must only contain alphabet")
    .max(30, "Maximum 30 characters are allowed")
    .required("Please enter your city"),
});

export const couponCodeFormSchema = yup.object().shape({
  // couponCode: yup.string().required("Coupon code is required"),
});
