import { useEffect, useState } from "react";
// import Image from "../../assets/images/DefaultProduct.png";
import Image from "../../assets/images/DefaultProduct.png";
import { IMAGE_BASE_URL } from "../../constants/constants";
import styles from "../../scss/common/common.module.scss";
import { Decrypt, imageOnError } from "../utils/Functions";

interface IProps {
  product: any;
  changeQuantity: (productId: number, quantity: number) => void;
  checkoutPage?: boolean;
  bundleErros: number[];
  setBundleErros: any;
}
const BundleItem = ({
  product,
  changeQuantity,
  checkoutPage = false,
  bundleErros,
  setBundleErros,
}: IProps) => {
  // const cartDataInfo = localStorage?.getItem("cart");
  // const cartAllData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
  const [productCartQty, setProductCartQty] = useState(0);
  // const [productCartQtyChange, setProductCartQtyChange] = useState(0);

  const [num, setNum] = useState(
    product.isSingleProduct
      ? product.quantity
      : product.store_product_detail.quantity
  );

  const [qtyError, setQtyError] = useState("");

  const addBundleError = () => {
    if (!bundleErros.includes(product.id)) {
      setBundleErros([...bundleErros, product.id]);
    }
  };

  const removeBundleError = () => {
    setBundleErros(bundleErros.filter((item) => item !== product.id));
  };

  // let incNum = () => {
  //   setQtyError("");
  //   if (product.isSingleProduct) {
  //     if (product.maximum_quantity && product.maximum_quantity !== null) {
  //       if (Number(num) + Number(productCartQty) < product.maximum_quantity) {
  //         setNum(Number(num) + 1);
  //       } else {
  //         setQtyError(
  //           "Maximum Quantity allowed is " + product.maximum_quantity
  //         );
  //       }
  //     } else {
  //       setNum(Number(num) + 1);
  //     }
  //   } else {
  //     if (
  //       product.store_product_detail.maximum_quantity &&
  //       product.store_product_detail.maximum_quantity !== null
  //     ) {
  //       if (
  //         Number(num) + Number(productCartQty) <
  //         product.store_product_detail.maximum_quantity
  //       ) {
  //         setNum(Number(num) + 1);
  //       } else {
  //         setQtyError(
  //           "Maximum Quantity allowed is " +
  //             product.store_product_detail.maximum_quantity
  //         );
  //       }
  //     } else {
  //       setNum(Number(num) + 1);
  //     }
  //   }
  // };
  // let decNum = () => {
  //   setQtyError("");
  //   if (product.isSingleProduct) {
  //     if (product.minimum_quantity && product.minimum_quantity !== null) {
  //       if (num > product.minimum_quantity) {
  //         setNum(num - 1);
  //       } else {
  //         setQtyError(
  //           "Minimum Quantity allowed is " + product.minimum_quantity
  //         );
  //       }
  //     } else {
  //       if (num > 1) {
  //         setNum(num - 1);
  //       } else {
  //         setQtyError("Minimum Quantity allowed is 1");
  //       }
  //     }
  //   } else {
  //     if (
  //       product.store_product_detail.minimum_quantity &&
  //       product.store_product_detail.minimum_quantity !== null
  //     ) {
  //       if (num > product.store_product_detail.minimum_quantity) {
  //         setNum(num - 1);
  //       } else {
  //         setQtyError(
  //           "Minimum Quantity allowed is " +
  //             product.store_product_detail.minimum_quantity
  //         );
  //       }
  //     } else {
  //       if (num > 1) {
  //         setNum(num - 1);
  //       } else {
  //         setQtyError("Minimum Quantity allowed is 1");
  //         setNum(1);
  //       }
  //     }
  //   }
  // };

  let handleChange = (e: any) => {
    if (e.target.value === "") {
      setNum("");
      setQtyError(
        "Minimum Quantity allowed is " +
          (product.isSingleProduct
            ? product?.minimum_quantity ?? "1"
            : product?.store_product_detail.minimum_quantity ?? "1")
      );
      addBundleError();
    } else if (e.target.value < 1) {
      setQtyError(
        "Minimum Quantity allowed is " +
          (product.isSingleProduct
            ? product?.minimum_quantity ?? "1"
            : product?.store_product_detail.minimum_quantity ?? "1")
      );
    } else {
      let minUpdate = false;
      let maxUpdate = false;
      if (product.isSingleProduct) {
        if (product.minimum_quantity && product.minimum_quantity !== null) {
          if (e.target.value >= product.minimum_quantity) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError(
              "Minimum Quantity allowed is " + product.minimum_quantity
            );
          }
        } else {
          if (e.target.value > 0) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError("Minimum Quantity allowed is 1");
          }
        }
        if (product.maximum_quantity && product.maximum_quantity !== null) {
          if (
            Number(e.target.value) + Number(productCartQty) <=
            product.maximum_quantity
          ) {
            maxUpdate = true;
          } else {
            maxUpdate = false;
            setQtyError(
              "Maximum Quantity allowed is " + product.maximum_quantity
            );
          }
        } else {
          if (e.target.value > 0) {
            maxUpdate = true;
          }
          // if()
        }
      } else {
        if (
          product.store_product_detail.minimum_quantity &&
          product.store_product_detail.minimum_quantity !== null
        ) {
          if (e.target.value >= product.store_product_detail.minimum_quantity) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError(
              "Minimum Quantity allowed is " +
                product.store_product_detail.minimum_quantity
            );
          }
        } else {
          if (e.target.value > 0) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError("Minimum Quantity allowed is 1");
          }
        }
        if (
          product.store_product_detail.maximum_quantity &&
          product.store_product_detail.maximum_quantity !== null
        ) {
          if (
            Number(e.target.value) + Number(productCartQty) <=
            product.store_product_detail.maximum_quantity
          ) {
            maxUpdate = true;
          } else {
            maxUpdate = false;
            setQtyError(
              "Maximum Quantity allowed is " +
                product.store_product_detail.maximum_quantity
            );
          }
        } else {
          if (e.target.value > 0) {
            maxUpdate = true;
          }
        }
      }

      if (minUpdate && maxUpdate) {
        removeBundleError();
        setQtyError("");
      } else {
        addBundleError();
      }
      if (parseInt(e.target.value) <= 1000) {
        setNum(parseInt(e.target.value));
      }
    }
  };
  useEffect(() => {
    changeQuantity(product.id, num);
  }, [num]);

  useEffect(() => {
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartAllData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
    if (product.isSingleProduct) {
      const cartItems = cartAllData.filter(
        (item: any) => item.id == product.id && item.isSingleProduct
      );

      let totalQty = 0;
      cartItems.map((item: any) => {
        if (item.cartItemId !== product.cartItemId) {
          totalQty += Number(item.quantity);
        }
      });

      setProductCartQty(totalQty);
    } else {
      const cartItems = cartAllData.find(
        (item: any) => item.id === product.bundle_id
      );

      if (cartItems) {
        const bundleProducts = cartItems.bundle_products;
        const cartBundleProducts = bundleProducts.filter(
          (item: any) => item.id == product.id
        );
        let totalQty = 0;
        cartBundleProducts.map((item: any) => {
          if (item.cartItemId !== product.cartItemId) {
            totalQty += Number(item.store_product_detail.quantity);
          }
        });

        setProductCartQty(totalQty);
      }
    }
    handleChange({
      target: {
        value: product.isSingleProduct
          ? product.quantity
          : product.store_product_detail.quantity,
      },
    });
  }, [product]);

  return (
    <div
      className={styles.itemModalCard}
      style={{ alignItems: checkoutPage ? "flex-start" : "center" }}
    >
      <div className={styles.itemModalContent}>
        <div className={styles.imgWrap}>
          <img
            loading="lazy"
            onError={(event) => imageOnError(event)}
            src={
              product.isSingleProduct
                ? product.store_product_images.length > 0
                  ? IMAGE_BASE_URL + product.store_product_images[0].image
                  : Image
                : product.store_product_detail.store_product_images.length > 0
                  ? IMAGE_BASE_URL +
                    product.store_product_detail.store_product_images[0].image
                  : Image
            }
            alt=""
          />
        </div>
        <div className={styles.itemModalHeading}>
          <h5>
            {product.isSingleProduct
              ? product.product_name
              : product.store_product_detail.product_name}
          </h5>

          {!product.isSingleProduct &&
            product.store_product_detail.store_options &&
            product.store_product_detail.store_options.length > 0 &&
            product.store_product_detail.store_options.map(
              (option: any, index: number) => {
                const item = option.store_option_items.filter(
                  (optionItem: any) => optionItem.id === option.selectedData
                );
                if (option.option_type === 1 || option.option_type === 2) {
                  return (
                    <p key={index}>
                      {option.display_name}:{" "}
                      {item.length > 0
                        ? item[0].option_name
                        : option.selectedData}{" "}
                    </p>
                  );
                }
              }
            )}

          {product.isSingleProduct &&
            product.store_options &&
            product.store_options.length > 0 &&
            product.store_options.map((option: any, index: number) => {
              const item = option.store_option_items.filter(
                (optionItem: any) => optionItem.id === option.selectedData
              );

              if (option.option_type === 1 || option.option_type === 2) {
                return (
                  <p key={index}>
                    {option.display_name}:{" "}
                    {item.length > 0
                      ? item[0].option_name
                      : option.selectedData}{" "}
                  </p>
                );
              }
            })}

          {/* <p>
            {product.isSingleProduct
              ? product.style
              : product.store_product_detail.style}
          </p> */}
        </div>
      </div>
      <>
        <div className={styles.itemModalPrice}>
          <span>
            $
            {product.isSingleProduct
              ? `${parseFloat((product.club_price * num).toString()).toFixed(
                  2
                )}`
              : `${parseFloat(
                  (product.store_product_detail.club_price * num).toString()
                ).toFixed(2)}`}
          </span>
          {!checkoutPage ? (
            <div className={styles.custom_incrementDecrement}>
              <div className={styles.input_group}>
                <div className={styles.input_group_prepend}>
                  <button
                    disabled={
                      num <=
                      (product.isSingleProduct
                        ? product?.minimum_quantity ?? 1
                        : product?.store_product_detail.minimum_quantity ?? 1)
                    }
                    className={styles.btn}
                    type="button"
                    onClick={() => handleChange({ target: { value: num - 1 } })}
                  >
                    -
                  </button>
                </div>
                <input
                  type="number"
                  className={styles.form_control}
                  value={num}
                  onChange={handleChange}
                />
                <div className={styles.input_group_prepend}>
                  <button
                    disabled={
                      num >=
                      (product.isSingleProduct
                        ? product?.maximum_quantity ?? 1000
                        : product?.store_product_detail?.maximum_quantity ??
                          1000)
                    }
                    className={styles.btn_outline}
                    type="button"
                    onClick={() => handleChange({ target: { value: num + 1 } })}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <p>Qty : {num}</p>
          )}

          <p className={styles.errorMsg}>{qtyError}</p>
        </div>
      </>
    </div>
  );
};

export default BundleItem;
