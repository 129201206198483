import { useEffect, useState } from "react";
import styles from "../../scss/pageComponent/checkoutCart.module.scss";
import checkMark from "../../assets/images/componentChecker.svg";

interface IProps {
  data?: any;
  click?: any;
  selected?: any;
  formSelect: any;
}
const CheckboxGraph = ({ data, click, selected, formSelect }: IProps) => {
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <>
      <div className={styles.deliveryFlow}>
        {data.map((val: any, index: any) => {
          return (
            <>
              <div
                onClick={() => {
                  click(val.value);
                }}
                className={styles.deliveryFlowInner}
              >
                {/* <div > */}
                <div className={styles.deliveryFlowcircle}>
                  <div className={styles.innerFlowCircle}>
                    <img loading="lazy" width="60%" src={val.img} alt="" />
                    {val.competedImg === true && (
                      <img
                        loading="lazy"
                        src={checkMark}
                        className={styles.flowCheckmark}
                        alt=""
                      />
                    )}
                  </div>
                </div>

                <p className={styles.deliveryFlowName}>{val.name}</p>
                {/* </div> */}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CheckboxGraph;
