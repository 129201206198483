/* eslint-disable no-nested-ternary */
import { FormControlLabel, InputLabel, Radio, RadioGroup } from "@mui/material";

interface IInputProps {
  formik?: any;
  name: string;
  options: { label: string; value: string }[];
  label?: string;
  value?: any;
  onChange?: (e: any) => void;
  required?: boolean;
}

export const RadioInputs = ({
  formik,
  name,
  options,
  label,
  value,
  onChange,
  required,
}: IInputProps) => {
  return (
    <div className="form_control">
      {label && (
        <InputLabel className="form_label" htmlFor={name}>
          {label} {required && <sup>*</sup>}
        </InputLabel>
      )}
      <RadioGroup
        row
        className="form_radio"
        name={name}
        value={value ? value : formik?.values[name]}
        onChange={onChange ? onChange : formik?.handleChange}
        id={name}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio disableRipple />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      <div className="error">
        {formik?.touched[name] && formik.errors[name]}
      </div>
    </div>
  );
};
