import React, { ReactNode } from "react";
import styles from "../../scss/common/common.module.scss";
import AppContainer from "../theme/Container";

interface IProps {
  heading: string;
  description: string | ReactNode;
  image: string;
  inContainer?: boolean;
}

const ImageBoxSection = ({
  heading,
  description,
  image,
  inContainer,
}: IProps) => {
  return (
    <section className={styles.imageBox_section}>
      <AppContainer
        maxWidth={inContainer ? "lg" : false}
        disableGutters={!inContainer}
      >
        <div
          className={styles.bg_image}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={styles.whiteBox}>
            <div className={styles.whiteBox_border}>
              <div className="heading">
                <h2>{heading}</h2>
                <p>{description}</p>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </section>
  );
};

export default ImageBoxSection;
