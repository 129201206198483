import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { appTheme } from "./muiThemes/muiTheme";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
if (process.env.REACT_APP_MODE_SAINTRY !== "Local") {
  Sentry.init({
    environment: process.env.REACT_APP_MODE_SAINTRY,
    dsn: process.env.REACT_APP_SENTRY_DNS_URL,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ["error", "debug"],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
      }),
    ],
    ignoreErrors: [
      "Non-Error promise rejection captured",
      "Failed to load Stripe.js",
      "TypeError: Failed to fetch",
      "Network Error",
      "Request failed with status code 0",
      "Script error",
      "Blocked by client",
      "ResizeObserver loop limit exceeded",
      "Non-Error promise rejection captured with value: null",
      "AbortError: The operation was aborted",
      "Failed to fetch",
    ],
    beforeSend(event) {
      try {
        if (event.message && event.message.includes("Rage Click")) {
          return null;
        }
        return event;
      } catch (error) {
        console.warn("Error in beforeSend processing:", error);
        return null;
      }
    },
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions

    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", "https://api.renathlete.net", "https://api.therenhub.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
