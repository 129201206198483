import storeNotFound from "../assets/images/ErorrLogo.svg";
import styles from "../scss/common/common.module.scss";

const StoreNotFound = () => {
  return (
    <div className={styles.storeNotFound} style={{ height: "100vh" }}>
      <div>
        <img loading="lazy" src={storeNotFound} alt="" />
        <h4>Store not found</h4>
        <span>Error 404</span>
        <p>
          We’re sorry, the store you requested could not be<br></br> found.
          Please check your link and try again.
        </p>
      </div>
    </div>
  );
};

export default StoreNotFound;
