import React from "react";
import { useNavigate } from "react-router-dom";

import ProductEmpty from "../../assets/images/productEmpty.svg";
// import ProductEmpty from "../../assets/images/productEmpty.svg";
import styles from "../../scss/common/common.module.scss";
import AppButton from "../theme/Button";

const ShoppingProductEmpty = () => {
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: "center" }}>
      <img
        loading="lazy"
        src={ProductEmpty}
        className={styles.emptyShoppingImg}
        alt=""
      />
      <div className={styles.emptyShopping}>
        <h4>Your shopping cart is empty!</h4>
        <p>Add items to your cart to see them here.</p>
        <AppButton onClick={() => navigate("/")}>Continue Shopping</AppButton>
      </div>
    </div>
  );
};

export default ShoppingProductEmpty;
