/* eslint-disable no-nested-ternary */
import { Checkbox, FormControlLabel, SwitchProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";
import {
  checkboxCheckedIcon,
  checkboxUncheckedIcon,
} from "../../assets/Icons/icons";

const useStyles = makeStyles({
  form_checkbox: {
    "&.MuiFormControlLabel-root": {
      margin: 0,
      gap: "8px",
    },
    "& .MuiTypography-root": {
      fontSize: "var(--f14)",
    },
    "& .MuiCheckbox-root": {
      padding: 0,
      "& svg": {
        width: "1.25rem",
        height: "1.25rem",
      },
    },
  },
});

const ThemeCheckbox = (
  props: SwitchProps & {
    label?: string | ReactNode;
    handleOnChange?: (
      name: string | ReactNode,
      e: React.ChangeEvent<HTMLInputElement>,
      checked?: boolean
    ) => void;
  }
) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.form_checkbox}
      control={
        <Checkbox
          {...props}
          disableRipple
          checked={props.checked}
          icon={checkboxUncheckedIcon}
          checkedIcon={checkboxCheckedIcon}
          onChange={(e) =>
            props.handleOnChange !== undefined
              ? props.handleOnChange(props.label, e)
              : ""
          }
        />
      }
      label={props.label ?? undefined}
    />
  );
};

// const ThemeCheckbox = styled((props: SwitchProps & { label?: string }) => (
//   <div className="form_checkbox">
//     <FormControlLabel
//       control={
//         <Checkbox
//           {...props}
//           disableRipple
//           icon={checkboxUncheckedIcon}
//           checkedIcon={checkboxCheckedIcon}
//         />
//       }
//       label={label ?? ""}
//     />
//   </div>
// ))(() => ({
//   padding: 0,
//   "& svg": {
//     width: "1.25rem",
//     height: "1.25rem",
//   },
// }));

export default ThemeCheckbox;
