import React, { ReactNode } from "react";
import {
  contactStarIcon,
  emailStarIcon,
  phoneStarIcon,
} from "../../assets/Icons/icons";
import styles from "../../scss/common/common.module.scss";
import AppContainer from "../theme/Container";

interface IProps {
  title: string;
  description: string;
  designation?: string;
  icon: ReactNode;
  mailto?: any;
}

const ContactCard = ({
  title,
  description,
  designation,
  icon,
  mailto,
}: IProps) => {
  return (
    <div className={styles.contact_card}>
      <div className={styles.cardIcon}>{icon}</div>
      <div className={styles.cardContent}>
        <h4>{title}</h4>
        {mailto ? (
          <a
            target="_top"
            href={`mailto:${description}`}
            style={{ textDecoration: "none", wordBreak: "break-word" }}
          >
            <h3>{description}</h3>
          </a>
        ) : (
          <h3 style={{ wordBreak: "break-word" }}>{description}</h3>
        )}
        <span className="custom_lineBreak">{designation}</span>
        <div className={styles.line} />
      </div>
    </div>
  );
};

const ContactDetailSection = ({ contactData }: any) => {
  return (
    <section className={styles.contact_section}>
      <div className={styles.contact_section_inner}>
        <AppContainer>
          <div className="heading">
            <h2
              style={{
                color:
                  contactData?.store_design_settings?.is_color_status === 2
                    ? contactData?.store_design_settings?.primary_color ?? ""
                    : "",
              }}
            >
              Contact or Need Help?
            </h2>
          </div>
          <div className={styles.contCard_wrapper}>
            <div className={styles.contact_card_col}>
              <ContactCard
                title="Contact"
                description={contactData?.customer_name ?? "Kelsey Renny"}
                designation={
                  contactData?.customer_designation
                    ? contactData?.customer_designation
                    : "Director"
                }
                icon={contactStarIcon}
              />
            </div>
            <div className={styles.contact_card_col}>
              <ContactCard
                title="Phone"
                description={`${
                  contactData?.customer_phone_number ?? "855-555-2222"
                }`}
                icon={phoneStarIcon}
              />
            </div>
            <div className={styles.contact_card_col}>
              <ContactCard
                title="Email"
                description={`${
                  contactData?.customer_email ?? "krenny@dallaspremier.com"
                }`}
                icon={emailStarIcon}
                mailto={`${contactData?.customer_email}`}
              />
            </div>
          </div>
        </AppContainer>
      </div>
    </section>
  );
};

export default ContactDetailSection;
