/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { InputField } from "../../common/theme/Input";
import Grid from "@mui/material/Grid";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import styles from "../../scss/pageComponent/checkoutCart.module.scss";
import { paymentForm } from "../../validations/CheckoutSchema";
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutThirdFormSlice } from "../../redux/CheckoutForm/CheckoutFormSlice";
import useFetch from "../../customHook/useFetch";
import { toast } from "react-toastify";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ThemeCheckbox from "../../common/theme/checkbox";
import AppButton from "../../common/theme/Button";
const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          display: "block",
          width: "100%",
          fontSize: "1.25rem",
          lineHeight: "1.5",
          color: "#212529",
          // backgroundColor: "#ffffff",
          backgroundClip: "padding-box",
          border: "1px solid #e5e5ea",
          appearance: "none",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
          letterSpacing: "0.025em",
          borderRadius: "0.5rem",
          fontFamily: "SF Pro Text",
          fontWeight: "400",
          fontStyle: "normal",
          "::placeholder": {
            color: "#aab7c4",
            content: "",
          },
        },
      },
    }),
    []
  );

  return options;
};
interface IProps {
  ShowSpecificForm?: any;
  idOfStore?: any;
  collectBillingInfo: boolean;
  paymentType: number;
  placeOrder: (first: any, second: any, value: any) => void;
  isLoading: any;
  setIsLoading: (value: boolean) => void;
  grandTotal: number;
}

interface clubStateListApi {
  id: number;
  state_name: string;
}

const CheckoutPayment = ({
  ShowSpecificForm,
  idOfStore,
  collectBillingInfo,
  paymentType,
  placeOrder,
  isLoading,
  setIsLoading,
  grandTotal,
}: IProps) => {
  const [clubStateList, setClubStateList] = useState([]);
  const [
    getClubStateList,
    { response: getClubStateResponse, error: getClubStateError },
  ] = useFetch("/us-states/list?start=0&limit=-1", {
    method: "get",
  });

  const [checkbox, setCheckBox] = useState(
    !ShowSpecificForm.ShippingForm === true ? false : true
  );
  const dispatch = useDispatch();

  const [cardNumberError, setCardNumberError] = useState<string>("");
  const options = useOptions();
  const elements = useElements();
  const stripe = useStripe();

  const [selectedFilter, setSelectedFilter] = useState<any>();

  const [cardNumberIsComplete, setCardNumberIsComplete] =
    useState<boolean>(false);
  const [expiryError, setExpiryError] = useState<string>("");
  const [cvcError, setCvcError] = useState<string>("");
  const [expiryIsComplete, setExpiryIsComplete] = useState<boolean>(false);
  const [cvcIsComplete, setCvcIsComplete] = useState<boolean>(false);
  const [stripToken, setStripToken] = useState("");

  const toggle = () => {
    formik.setFieldValue("checkbox", !checkbox);
    setCheckBox(!checkbox);
  };
  const storeDesignSettingData = useSelector(
    (state: any) => state.storeCheckoutForm.ThirdFormSlice
  );

  const handleChangeCardNumber = async (event: any) => {
    setCardNumberError(event.error ? event.error.message : "");
    setCardNumberIsComplete(event.complete);
  };

  const handleChangeExpiry = async (event: any) => {
    setExpiryError(event.error ? event.error.message : "");
    setExpiryIsComplete(event.complete);
  };

  const handleChangeCvc = async (event: any) => {
    setCvcError(event.error ? event.error.message : "");
    setCvcIsComplete(event.complete);
  };

  const formik: any = useFormik({
    initialValues: {
      checkbox: checkbox,
      token: "",
      firstName: storeDesignSettingData.firstName || "",
      lastName: storeDesignSettingData.lastName || "",
      street: storeDesignSettingData.street || "",
      aptUnit: storeDesignSettingData.aptUnit || "",
      state: storeDesignSettingData.state || "",
      zipCode: storeDesignSettingData.zipCode || "",
      city: storeDesignSettingData.city || "",
    },
    // validationSchema: {},
    validationSchema: paymentForm,
    onSubmit: async (values) => {
      setIsLoading(true);
      if (paymentType != 2) {
        if (elements && stripe) {
          const cardElement = elements.getElement(CardNumberElement);
          const cardExpiryElement = elements.getElement(CardExpiryElement);
          const cardCvcElement = elements.getElement(CardCvcElement);

          if (!cardElement || !cardExpiryElement || !cardCvcElement) {
            // One or more elements are not mounted
            console.error("Stripe Elements are not mounted correctly.");
            return;
          }
          if (cardElement) {
            const response = await stripe.createToken(cardElement);
            if (response.token) {
              setStripToken(response.token.id);
              values.token = response.token.id;

              placeOrder(null, null, values);
            } else if (response.error) {
              toast.error(response.error.message);

              setIsLoading(false);
            }

            // setStripToken()
          }
        }
      } else {
        dispatch(setCheckoutThirdFormSlice(values));
        placeOrder(null, null, values);
      }
    },
  });

  useEffect(() => {
    getClubStateList();
  }, []);

  useEffect(() => {
    if (getClubStateResponse?.status) {
      const options = getClubStateResponse?.data?.list.map(
        (data: clubStateListApi) => ({
          label: data?.state_name,
          value: data?.id,
        })
      );
      if (options.length) setClubStateList(options.reverse());
    } else {
      toast.error(getClubStateError?.message);
    }
  }, [getClubStateResponse, getClubStateError]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className={styles.CheckoutCart}>
          {paymentType !== 2 && grandTotal !== 0 && (
            <>
              <Grid md={12}>
                {/* <Form.Check
                    type="checkbox"
                    checked={saveCard}
                    onChange={() => setSaveCard(!saveCard)}
                    label="Save my card"
                    id="default-checkbox"
                    className="cardCheck"
                  /> */}
              </Grid>

              {/* <CardNumberElement onChange={handleChangeCardNumber} />{" "} */}
              <Grid item lg={6} xs={12}>
                <InputLabel
                  className="form_label"
                  style={{ textTransform: "capitalize" }}
                >
                  Credit Card Number
                </InputLabel>
                <div className="form_input_wrap">
                  <CardNumberElement
                    className={styles.stripeInput}
                    options={options}
                    onChange={handleChangeCardNumber}
                  />
                </div>
                <div
                  className="invalid-feedback"
                  style={{
                    display: cardNumberError ? "block" : "none",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {cardNumberError}
                </div>
                {/* <InputField
                  formik={formik}
                  label="Credit Card Number"
                  placeholder="Enter number"
                  name="Card"
                /> */}
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputLabel
                  className="form_label"
                  style={{ textTransform: "capitalize" }}
                >
                  Expiration
                </InputLabel>

                <CardExpiryElement
                  className={styles.stripeInput}
                  options={options}
                  onChange={handleChangeExpiry}
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display: expiryError ? "block" : "none",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {expiryError}
                </div>
                {/* <InputField
                  formik={formik}
                  label="Expiration"
                  placeholder="MM/YY"
                  name="expiry"
                /> */}
              </Grid>
              <Grid item lg={12} xs={12}>
                <InputLabel
                  className="form_label"
                  style={{ textTransform: "capitalize" }}
                >
                  Security Code
                </InputLabel>
                <CardCvcElement
                  className={styles.stripeInput}
                  options={options}
                  onChange={handleChangeCvc}
                />
                <div
                  className="invalid-feedback"
                  style={{
                    display: cvcError ? "block" : "none",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {cvcError}
                </div>
                {/* <InputField
                  formik={formik}
                  label="Security Code"
                  placeholder="Enter cvv"
                  name="cvv"
                  // disabled={true}
                /> */}
              </Grid>
            </>
          )}

          {ShowSpecificForm.ShippingForm === true && (
            <Grid item lg={12} xs={12}>
              <Grid item lg={12} xs={12} className={styles.textEditor_wrap}>
                {/* <label className="form_label">Collected Names</label> */}
                <div className={styles.checkField_wrapper}>
                  <ThemeCheckbox checked={checkbox} onClick={toggle} />
                  <div className="static_input">
                    <span>Billing address same as shipping address</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>

        {(!checkbox || !ShowSpecificForm.ShippingForm) && (
          <>
            <Grid container spacing={2} className={styles.CheckoutCart}>
              <Grid style={{ marginTop: "20px" }} item lg={12} xs={12}>
                <span>Billing address</span>
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputField
                  formik={formik}
                  label="First Name"
                  placeholder="Enter first name"
                  name="firstName"
                  required={true}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputField
                  formik={formik}
                  label="Last Name"
                  placeholder="Enter last name"
                  name="lastName"
                  required={true}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputField
                  // required
                  formik={formik}
                  label="Street"
                  placeholder="Enter street"
                  name="street"
                  // disabled={true}
                  required={true}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputField
                  formik={formik}
                  label="Apt/Unit"
                  placeholder="Enter apt/unit"
                  name="aptUnit"
                  // required={true}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                <InputField
                  formik={formik}
                  label="City"
                  placeholder="Enter city"
                  name="city"
                  required={true}
                />
              </Grid>

              <Grid item lg={6} xs={12}>
                {/* <SelectField
                  label="State"
                  name="state"
                  placeholder="Select state"
                  formik={formik}
                  options={clubStateList}
                  required={true}
                /> */}
                <div className={`form_control statusWrap`}>
                  <InputLabel className="form_label">
                    State<sup>*</sup>
                  </InputLabel>
                  <Autocomplete
                    options={clubStateList}
                    className={`form_select`}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select state" />
                    )}
                    classes={{
                      popper: "customPopper",
                    }}
                    value={selectedFilter}
                    onChange={(event, values) => {
                      setSelectedFilter(values);
                      formik?.setFieldValue("state", values?.value || "");
                    }}
                  />
                  <div className="error">
                    {formik?.touched.state && formik?.errors.state}
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} xs={12}>
                <InputField
                  formik={formik}
                  label="Zip Code"
                  placeholder="Enter zip code"
                  name="zipCode"
                  required={true}
                />
              </Grid>
            </Grid>
          </>
        )}

        <div className={styles.checkoutBtn}>
          <AppButton
            className={styles.continue}
            type="submit"
            disabled={isLoading}
            // onClick={placeOrder}
          >
            <span style={{ display: "flex", justifyContent: "center" }}>
              Place Order
            </span>
          </AppButton>
        </div>
      </form>
    </>
  );
};

export default CheckoutPayment;
