import React from "react";
import TravelPremierPackage from "./TravelPremierPackage";

const TravelPremierPackageCards = () => {
  return (
    <div>
      <TravelPremierPackage storeBundleData={[]} />
    </div>
  );
};

export default TravelPremierPackageCards;
