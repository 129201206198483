import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../common/layouts/Layout";
import AppContainer from "../common/theme/Container";
import ProductDetailCard from "../pageComponents/productDetail/ProductDetailCard";
import ShoppingCards from "../pageComponents/bundleCart/shoppingCards";

const ProductDetail = () => {
  const { id } = useParams();
  return (
    <>
      <Layout>
        <AppContainer>
          <ProductDetailCard />
          <ShoppingCards product_id={[id ?? ""]} />
        </AppContainer>
      </Layout>
    </>
  );
};

export default ProductDetail;
