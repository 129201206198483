import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCartCount } from "../../redux/cart";
import { useNavigate } from "react-router-dom";
import styles from "../../scss/common/common.module.scss";
import AppButton from "../../common/theme/Button";
import ProductCartShoppingCard from "./productCartShoppingCard";
import ShoppingProductEmpty from "../../common/sections/ShoppingProductEmpty";
import { Decrypt } from "../../common/utils/Functions";
interface IProps {
  product_id_set: any;
  set_product_id_set: any;
}

const BundleCartShoppingCard = ({
  product_id_set,
  set_product_id_set,
}: IProps) => {
  const navigate = useNavigate();
  const storeData = useSelector((state: any) => state.storeDataSlice.data);

  // const [open, setOpen] = React.useState(false);
  const [reLoadCart, setReLoadCart] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => {
  //   setReLoadCart(!reLoadCart);
  //   setOpen(false);
  // };
  const [cart, setCart] = useState<any>();

  const dispatch = useDispatch();
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalProductItem, setTotalProductItem] = useState(0);
  const [isContinueDisable, setIsContinueDisable] = useState(false);
  // const [cartImages, setCartImages] = useState([]);

  const emptyCart = () => {
    localStorage.removeItem("cart");
    setReLoadCart(!reLoadCart);
  };

  useEffect(() => {
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

    setCart(cartData);
    if (cartData) dispatch(setCartCount(cartData.length));
  }, [reLoadCart]);

  useEffect(() => {
    if (cart) {
      let totalPrice = 0;
      let totalItem = 0;
      const idArray: number[] = [];

      cart.map((cartItem: any) => {
        idArray.push(cartItem.id);
        if (cartItem.isSingleProduct) {
          totalPrice += cartItem.club_price * cartItem.quantity;
          totalItem += Number(cartItem.quantity);
        } else {
          if (storeData?.payment_handle_type === 1) {
            cartItem.bundle_products &&
              cartItem.bundle_products.map((product: any) => {
                totalItem += Number(product.store_product_detail.quantity);
                totalPrice +=
                  product.store_product_detail.club_price *
                  product.store_product_detail.quantity;
              });
          }
        }
      });
      set_product_id_set(new Set(idArray));
      setTotalProductItem(totalItem);
      setTotalProductPrice(totalPrice);
    }
  }, [cart, reLoadCart]);

  return (
    <>
      {cart && cart.length > 0 ? (
        <div>
          <div className={styles.shoppingChat_checkoutBtn_mob_view}>
            <h2>Shopping Cart</h2>
            <AppButton
              onClick={emptyCart}
              disabled={cart && cart.length <= 0}
              style={{
                cursor: cart && cart.length <= 0 ? "default" : "pointer",
              }}
              className={styles.empty_mob_view}
            >
              Empty Cart
            </AppButton>
          </div>

          <div className={styles.shoppingCart}>
            {cart && cart.length > 0 ? (
              <>
                {cart &&
                  cart.map((cartItem: any, index: number) => (
                    <ProductCartShoppingCard
                      cartItem={cartItem}
                      reLoadCart={reLoadCart}
                      setReLoadCart={setReLoadCart}
                      setIsContinueDisable={setIsContinueDisable}
                      itemIndex={index}
                      key={index}
                    />
                  ))}
                <div className={styles.shoppingChat_subtotal}>
                  <p>
                    Subtotal ({totalProductItem} items) :{" "}
                    <b>{`$${parseFloat(totalProductPrice.toString()).toFixed(
                      2
                    )}`}</b>
                  </p>
                </div>
              </>
            ) : (
              <p style={{ textAlign: "center", padding: "10px" }}>
                Your Cart is Empty
              </p>
            )}
          </div>

          <div className={styles.shoppingChat_checkoutBtn}>
            <AppButton
              onClick={emptyCart}
              disabled={cart && cart.length <= 0}
              style={{
                cursor: cart && cart.length <= 0 ? "default" : "pointer",
              }}
              className={styles.empty}
            >
              Empty Cart
            </AppButton>
            <div className={styles.rightWrap}>
              <AppButton
                disabled={cart && cart.length <= 0}
                className={`${styles.continue} ${styles.shoppingBtn}`}
                style={{
                  textTransform: "initial",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue to Shopping
              </AppButton>
              <AppButton
                disabled={(cart && cart.length <= 0) || isContinueDisable}
                style={{
                  cursor: cart && cart.length <= 0 ? "default" : "pointer",
                  textTransform: "initial",
                  // text-transform: "initial !important",
                }}
                className={styles.continue}
                onClick={() => {
                  navigate("/checkout-cart");
                }}
              >
                Continue to Checkout
              </AppButton>
            </div>
          </div>
          {/* <ShoppingCards
            title="Westside Spandex"
            price="$250 - $300"
            button="Add to Chat"
          /> */}
        </div>
      ) : (
        <ShoppingProductEmpty />
      )}
    </>
  );
};

export default BundleCartShoppingCard;
