import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DIRECT_ACCESS_URL, IMAGE_BASE_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import styles from "../../scss/common/ProductCard.module.scss";
import { imageOnError } from "../../common/utils/Functions";
import AppContainer from "../../common/theme/Container";
import AppButton from "../../common/theme/Button";

interface IProps {
  optionalData?: string | null;
}

const OptionalItems = ({ optionalData }: IProps) => {
  const navigate = useNavigate();
  const [storeOptionalData, setStoreOptionalData] = useState<Array<any>>([]);
  /** Get direct access url */
  const direct_access_url = DIRECT_ACCESS_URL;
  /** Api call function start */
  const [getOptionalList, { response: storeOptionalRes }] = useFetch(
    `/store-product/public-list/${direct_access_url}?start=0&limit=-1`,
    {
      method: "get",
    }
  );

  // use for local test
  // const [
  //   getOptionalList,
  //   { response: storeOptionalRes, loading: storeOptionalLoading },
  // ] = useFetch(
  //   `/store-product/public-list/store-one?start=0&limit=-1`,
  //   {
  //     method: "get",
  //   }
  // );

  /** Api call function end */
  useEffect(() => {
    getOptionalList();
  }, []);
  useEffect(() => {
    if (storeOptionalRes) {
      setStoreOptionalData(storeOptionalRes?.data?.list ?? []);
    }
  }, [storeOptionalRes]);

  return (
    <>
      {storeOptionalData?.length > 0
        ? storeOptionalData.map((item, index) => (
            <section className={styles.travel_package} key={index}>
              <AppContainer>
                <div className="heading">
                  <h2>{item.category_name}</h2>
                  {/* <span>
                    {optionalData
                      ? optionalData
                      : "We take pride in providing the best."}
                  </span> */}
                </div>
                <div className={styles.product_row}>
                  {item?.store_products?.map((item: any, index: number) => (
                    <div className={styles.product_lg} key={index}>
                      <div className={styles.product_card}>
                        <div className={styles.card_img}>
                          {item?.store_product_images.length > 0 &&
                          item?.store_product_images?.[0]?.image ? (
                            <img
                              loading="lazy"
                              onError={(event) => imageOnError(event)}
                              src={`${IMAGE_BASE_URL}${item?.store_product_images?.[0]?.image}`}
                              alt="img"
                            />
                          ) : (
                            <img
                              loading="lazy"
                              src="/images/default-product.png"
                              alt="img"
                            />
                          )}
                        </div>
                        <div className={styles.card_content}>
                          <div className={styles.title}>
                            <h6>{item?.product_name}</h6>
                          </div>
                          <div>
                            <div className={styles.price}>
                              <h5>
                                {item?.club_price === null
                                  ? `$${(0).toFixed(2)}`
                                  : `$${parseFloat(item?.club_price).toFixed(
                                      2
                                    )}`}
                              </h5>
                            </div>

                            <AppButton
                              onClick={() =>
                                navigate(`/product-detail/${item?.id}`)
                              }
                            >
                              View details
                            </AppButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </AppContainer>
            </section>
          ))
        : ""}
    </>
  );
};

export default OptionalItems;
