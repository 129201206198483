// import styles from "../scss/home.module.scss";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../common/theme/Button";
import { DIRECT_ACCESS_URL } from "../constants/constants";
import useFetch from "../customHook/useFetch";
import {
  clearLoader,
  setClearSearch,
  setFilterLoader,
  setSearch,
} from "../redux/StoreDesignList/StoreDesignListSlice";
import ModalLayout from "../common/layouts/ModleLayout";
import OptionalItems from "../pageComponents/home/OptionalItems";
import ImageBoxSection from "../common/sections/ImageBoxSection";
import TravelPremierPackageCards from "../pageComponents/home/TravelPremierPackageCards";
import TravelPremierPackage from "../pageComponents/home/TravelPremierPackage";
import TeamPackageSection from "../pageComponents/home/TeamPackageSection";
import Layout from "../common/layouts/Layout";

const Home = () => {
  const dispatch = useDispatch();
  const storeData = useSelector((state: any) => state.storeDataSlice.data);

  const Loader = useSelector((state: any) => state.storeDesignList.loader);
  const state = useSelector((state: any) => state.storeDesignList);
  const [allLoader, setAllLoading] = useState(false);
  /** Get store Design list from store **/
  const storeDesignSettingData = useSelector(
    (state: any) => state.storeDesignList
  );
  const [storeBundleData, setStoreBundleData] = useState<Array<any>>([]);
  const [storeDesignData, setStoreDesignData] = useState<any>({});

  const [openModal, setOpenModal] = useState(false);
  /** Get direct access url */
  const direct_access_url = DIRECT_ACCESS_URL;

  /** Api call function start */
  const [getStoreBundle, { response: storeBundleRes }] = useFetch(
    `/get-store-bundle-new/${direct_access_url}`,
    {
      method: "post",
    }
  );
  /** Api call function end */
  useEffect(() => {
    if (storeDesignSettingData?.searchState) {
      const filterIds = storeDesignSettingData?.teamFilter?.map(
        (item: any) => item?.value
      );
      const payload = {
        filter_by_team_ids: filterIds ?? [],
      };
      // dispatch(setFilterLoader(true));
      getStoreBundle(payload);
    }
  }, [storeDesignSettingData?.teamFilter, storeDesignSettingData?.searchState]);
  useEffect(() => {
    if (storeBundleRes) {
      dispatch(setClearSearch(false));
      dispatch(clearLoader());
      dispatch(setFilterLoader(false));
      dispatch(setSearch(false));
      setStoreBundleData(storeBundleRes?.data?.bundles ?? []);
    }
  }, [storeBundleRes]);
  useEffect(() => {
    if (storeDesignSettingData) {
      setStoreDesignData(storeDesignSettingData?.data ?? {});
    }
  }, [storeDesignSettingData]);
  useEffect(() => {
    if (Loader) {
      setAllLoading(true);
    } else {
      setAllLoading(false);
    }
  }, [Loader]);

  useEffect(() => {
    const currentDate = moment();
    const closeDateTm = moment.unix(storeData?.close_date_time);
    if (currentDate.diff(closeDateTm, "milliseconds") < 0) {
      if (storeData?.welcome_message) {
        setOpenModal(true);
      }
    }
  }, [storeData?.welcome_message]);

  useEffect(() => {
    const payload = {
      filter_by_team_ids: [],
    };
    getStoreBundle(payload);
  }, [state?.clearSearch]);

  return (
    <>
      {allLoader ? (
        <span className="loadingBarsScreen">
          <RotatingLines
            strokeColor="currentColor"
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
          />
        </span>
      ) : (
        <Layout isHomePage>
          {storeDesignData?.is_team_filter_show !== 2 &&
          storeDesignData?.is_team_available !== 0 ? (
            <TeamPackageSection />
          ) : (
            ""
          )}

          <TravelPremierPackage
            storeBundleData={storeBundleData}
            // loading={storeBundleLoading}
            isBundles
          />
          {/* {Number(storeDesignData?.is_vector_image_show) !== 2 && (
            <ShopOptionalSection />
          )} */}
          <TravelPremierPackageCards />

          {storeDesignData?.is_our_product_section_show !== 2 && (
            <ImageBoxSection
              inContainer
              heading={
                storeDesignData !== null &&
                storeDesignData?.our_product_section_title &&
                storeDesignData?.our_product_section_title !== null
                  ? storeDesignData?.our_product_section_title
                  : "Our Products"
              }
              description={
                <>
                  {storeDesignData !== null &&
                  storeDesignData?.our_product_section_description &&
                  storeDesignData?.our_product_section_description !== null ? (
                    storeDesignSettingData?.data
                      ?.our_product_section_description
                  ) : (
                    <>
                      We take pride in providing the best. Our premium,
                      exclusive fabrics and our performance driven designs are
                      crafted to enhance every movement. Our products are 100%
                      custom to complement all styles and personal preferences.
                    </>
                  )}
                </>
              }
              image="/images/ourProductBg.jpg"
            />
          )}

          <OptionalItems
            optionalData={storeDesignData?.optional_item_description ?? null}
          />
        </Layout>
      )}
      <ModalLayout
        modalWidth={767}
        open={openModal}
        headingCenter
        handleClose={() => setOpenModal(!openModal)}
        handleOpen={() => setOpenModal(!openModal)}
        title=""
      >
        <div
          dangerouslySetInnerHTML={{ __html: storeData?.welcome_message ?? "" }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <AppButton onClick={() => setOpenModal(!openModal)}>Close</AppButton>
        </div>
      </ModalLayout>
    </>
  );
};

export default Home;
