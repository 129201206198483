import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";

const useStyles = makeStyles({
  modal_layout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    width: "95%",
    minHeight: 200,
    borderRadius: 8,
    padding: "2.5rem",
    maxHeight: "90vh",
    overflowY: "auto",
    backgroundColor: "#fff",
  },
  icon: {
    "& svg": {
      display: "inline-block",
      width: "1.5rem",
      height: "auto",
      marginBottom: 10,
    },
  },
});
interface IProps {
  headingCenter?: boolean;
  modalWidth: number;
  icon?: ReactNode;
  title: string;
  subtitle?: string | ReactNode;
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  children: ReactNode;
  close?: boolean;
}
const ModalLayout = ({
  headingCenter,
  modalWidth,
  icon,
  title,
  subtitle,
  handleClose,
  handleOpen,
  open,
  children,
  close,
}: IProps) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.modal_layout}
    >
      <div
        className={classes.modal}
        style={{ maxWidth: modalWidth, position: "relative" }}
      >
        {close ? (
          <div
            onClick={handleClose}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "1rem",
              borderRadius: "50%",
              backgroundColor: "#ccc",
              padding: "5px 10px",
            }}
          >
            X
          </div>
        ) : (
          ""
        )}
        <div className={`section_heading ${headingCenter ? "center" : ""}`}>
          {icon && <div className={classes.icon}>{icon}</div>}
          <h4>{title}</h4>
          {subtitle &&
            (typeof subtitle === "string" ? <p>{subtitle}</p> : subtitle)}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalLayout;
