import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { InputField } from "../../common/theme/Input";
import { SelectField } from "../../common/theme/select";
import { IMAGE_BASE_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import { setProductData } from "../../redux/productData";
import styles from "../../scss/common/ProductCard.module.scss";
import {
  Decrypt,
  Encrypt,
  imageOnError,
  strip_html_tags,
} from "../../common/utils/Functions";
import AppButton from "../../common/theme/Button";

const ProductDetailCard = () => {
  const params = useParams();
  // const productGetId = params.id;
  const navigate = useNavigate();
  const [productId, setProductId] = useState(params.id);
  const [error, setError] = useState("");
  const [bundleErros, setBundleErros] = useState(false);
  const [qty, setQty] = useState<any>("");

  const [bigImage, setBigImage] = useState("");
  const [productCartQty, setProductCartQty] = useState(0);
  const [qtyError, setQtyError] = useState(false);
  // const [age, setAge] = React.useState("");
  const [saveOptopnList, setSaveOptionList] = useState<any>([]);
  const [singleProductData, setSingleProductData] = useState<any>();

  const cartDataInfoD = localStorage?.getItem("cart");
  const cartDataInfo = Decrypt(cartDataInfoD);
  const cartAllData =
    cartDataInfo && cartDataInfo !== null ? JSON.parse(cartDataInfo) : [];
  const [disableButton, setDisableButton] = useState(false);
  const [saveProductChange, setSaveProductChange] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [
    loadSingleProductQuery,
    {
      response: responseSingleProduct,
      // loading: loadingSingleProduct,
      error: errorSingleProduct,
    },
  ] = useFetch(`/get-products-info/public/${productId}`, {
    method: "get",
  });

  useEffect(() => {
    setLoading(true);
    setError("");
    setProductId(params.id);
    setBundleErros(false);
  }, [params.id]);

  useEffect(() => {
    setTimeout(() => {
      loadSingleProductQuery();
    }, 100);
  }, [productId]);

  useEffect(() => {
    if (
      responseSingleProduct &&
      responseSingleProduct.code === 200 &&
      responseSingleProduct.status === 1
    ) {
      const bundleProductOptions = responseSingleProduct.data.store_options.map(
        (option: any) => {
          return {
            ...option,
            selectedData: 0,
          };
        }
      );

      setSingleProductData({
        ...responseSingleProduct.data,
        store_options: bundleProductOptions,
      });

      dispatch(setProductData(responseSingleProduct.data));
      setLoading(false);
    }
  }, [responseSingleProduct, errorSingleProduct]);

  const saveProduct = (
    itemId: String,
    optionsId: any,
    value: any,
    price: any = 0
  ) => {
    const newSaveOptopnList = saveOptopnList;
    const getOptionIndex = saveOptopnList.findIndex(
      (option: any) =>
        option.optionsId === optionsId && option.productId === itemId
    );
    if (getOptionIndex >= 0) {
      newSaveOptopnList[getOptionIndex].value = value;
      if (optionsId === "quantity") {
        // setQty(value);
        newSaveOptopnList[getOptionIndex].price = price;
      } else {
      }
      setSaveOptionList(newSaveOptopnList);
    }
    setSaveProductChange(!saveProductChange);
  };

  const addToCart = () => {
    const data = saveOptopnList.filter((product: any) => {
      return product.value === "";
    });

    let error = false;
    if (data.length > 0) {
      const errorIndex = data.findIndex(
        (list: any) => list.optionsId === "quantity"
      );

      if (errorIndex >= 0) {
        setQtyError(true);
        setError("");
        setBundleErros(false);
      } else {
        setQtyError(false);
      }
      setCheck(true);

      error = true;
    } else {
      setQtyError(false);
      setCheck(false);
      error = false;
    }

    if (!error) {
      let newSingleProductData = singleProductData;
      saveOptopnList.map((productSave: any) => {
        const index = newSingleProductData.store_options.findIndex(
          (option: any) => productSave.optionsId === option.id
        );
        if (newSingleProductData.store_options[index]) {
          if (newSingleProductData.store_options[index].selectedData === 0) {
            newSingleProductData.store_options[index].selectedData =
              productSave.value;
          }
          // else {
          //   let newOptionData =
          //     newSingleProductData.store_options[index];
          //   newOptionData = {
          //     ...newOptionData,
          //     selectedData: productSave.value,
          //   };
          //   // newSingleProductData = { ...newSingleProductData.store_options[index], selectedData:productSave.value};
          //   newSingleProductData = {
          //     ...newSingleProductData,
          //     store_options: newOptionData,
          //   };
          //   // newSingleProductData.store_options[index].selectedData =
          //   // productSave.value;
          // }
        }

        if (productSave.optionsId === "quantity") {
          if (newSingleProductData.quantity) {
            newSingleProductData.quantity = productSave.value;
          } else {
            newSingleProductData = {
              ...newSingleProductData,
              quantity: productSave.value,
            };
            // setSingleProductData({
            //   ...singleProductData,
            //   quantity: productSave.value,
            // });
          }
        }
      });

      let newCartData: any = [];
      // const cartDataInfoD = localStorage?.getItem("cart");
      // const cartDataInfo = Decrypt(cartDataInfoD);
      // const cartAllData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

      if (cartAllData && cartAllData.length > 0) {
        newCartData = cartAllData;

        const cartItem = newCartData.filter(
          (cartProduct: any) =>
            cartProduct.id === newSingleProductData.id &&
            cartProduct.isSingleProduct
        );

        if (cartItem && cartItem.length > 0) {
          let newItem = false;
          let sameItem = "";
          let isUpdate = false;

          cartItem.map((item: any, index: any) => {
            const findSameOption: any = [];

            item.store_options.map((storeOption: any) => {
              const findData = newSingleProductData.store_options.filter(
                (option: any) =>
                  storeOption.global_option_id === option.global_option_id &&
                  storeOption.selectedData === option.selectedData
              );
              if (findData.length > 0) {
                findSameOption.push(findData);
              }
            });

            if (findSameOption.length < item.store_options.length) {
              if (!isUpdate) {
                newItem = true;
              }
            } else {
              newItem = false;
              sameItem = item.cartItemId;
              isUpdate = true;
            }
          });

          const maxCartItem = Math.max(
            ...cartAllData.map((o: any) => Number(o.cartItemId)),
            0
          );
          if (newItem) {
            newCartData.push({
              ...newSingleProductData,
              isSingleProduct: true,
              cartItemId: maxCartItem + 1,
            });
          } else {
            const itemIndex = cartAllData.findIndex(
              (item: any) => item.cartItemId === sameItem
            );

            let oldQty = newCartData[itemIndex].quantity;

            newCartData[itemIndex].quantity =
              Number(oldQty) + Number(newSingleProductData.quantity);
          }

          // newCartData.splice(cartItem, 1);
          // newCartData.push({ ...newSingleProductData, isSingleProduct: true });
        } else {
          const maxCartItem = Math.max(
            ...cartAllData.map((o: any) => Number(o.cartItemId)),
            0
          );

          newCartData.push({
            ...newSingleProductData,
            isSingleProduct: true,
            cartItemId: maxCartItem + 1,
          });
        }
        const cartData = JSON.stringify(newCartData);

        const cartDataEn = Encrypt(cartData);
        localStorage.setItem("cart", cartDataEn);
      } else {
        const cartBundleData = [];
        cartBundleData.push({
          ...newSingleProductData,
          isSingleProduct: true,
          cartItemId: 1,
        });
        const cartData = JSON.stringify(cartBundleData);

        const cartDataEn = Encrypt(cartData);
        localStorage.setItem("cart", cartDataEn);
      }
      navigate("/cart");
    }
  };

  // useEffect(() => {
  //   const data = saveOptopnList.filter((product: any) => {
  //     return product.value === ""
  //   });
  //   if (data.length > 0) {
  //
  //     setCheck(false);
  //   } else {
  //     setCheck(true);
  //   }
  // }, [saveOptopnList, saveProductChange]);

  const changeQty = (newQtyData: any) => {
    let newQty = newQtyData;
    setError("");
    setQty(newQty);
    let update = false;
    if (
      singleProductData.minimum_quantity &&
      singleProductData.maximum_quantity
    ) {
      if (Number(newQty) < Number(singleProductData.minimum_quantity)) {
        update = false;
        setError(
          "Minimum Quantity allowed is " + singleProductData.minimum_quantity
        );
      } else if (
        Number(newQty) + Number(productCartQty) >
        singleProductData.maximum_quantity
      ) {
        update = false;
        setError(
          "Maximum Quantity allowed is " + singleProductData.maximum_quantity
        );
      } else if (parseInt(newQty) > 0) {
        update = true;
      } else {
        setQty("");
        setError("Minimum Quantity allowed is 1");
        setBundleErros(true);
      }
    } else {
      if (newQty === "" || parseInt(newQty) < 1) {
        setError("Minimum Quantity allowed is 1");
        setBundleErros(true);
      } else if (newQty > 0) {
        update = true;
      }
    }

    if (update) {
      setError("");
      setBundleErros(false);
    } else {
      setBundleErros(true);
    }

    if (parseInt(newQty) < 1000) {
      setQty(parseInt(newQty));
    }

    saveProduct(
      singleProductData?.id,
      "quantity",
      newQty,
      singleProductData?.club_price
    );
  };
  useEffect(() => {
    const saveList: any = [];
    if (singleProductData) {
      saveList.push({
        productId: singleProductData.id,
        options: {},
        optionsId: "quantity",
        value:
          singleProductData && singleProductData.minimum_quantity
            ? singleProductData.minimum_quantity
            : 1,
      });

      singleProductData.store_options.map((options: any) => {
        saveList.push({
          productId: singleProductData.id,
          options,
          optionsId: options.id,
          value: "",
        });
      });
      setQty(singleProductData?.minimum_quantity ?? 1);
      setSaveOptionList(saveList);

      setBigImage(
        singleProductData &&
          singleProductData.store_product_images &&
          singleProductData.store_product_images.length > 0
          ? IMAGE_BASE_URL + singleProductData.store_product_images[0].image
          : "/images/default-product.png"
      );
    }
  }, [singleProductData]);

  useEffect(() => {
    const cartItems = cartAllData.filter((item: any) => item.id == productId);

    let totalQty = 0;
    cartItems.map((item: any) => {
      totalQty += Number(item.quantity);
    });

    setProductCartQty(totalQty);
  }, [cartAllData, productId]);

  return loading ? (
    <span className="loadingBarsScreen">
      <RotatingLines
        strokeColor="currentColor"
        strokeWidth="5"
        animationDuration="0.75"
        width="20"
      />
    </span>
  ) : (
    <>
      <div className={styles.productDetailCard}>
        <Box sx={{ flexGrow: 1, overflow: "hidden" }}>
          <Grid container>
            {/* start here */}
            {singleProductData?.store_product_images?.length > 0 ? (
              <Grid item lg={2} sm={4} xs={4} className={styles.custom_img}>
                {singleProductData &&
                  singleProductData.store_product_images.length > 0 &&
                  singleProductData.store_product_images.map(
                    (imagesData: any, index: number) => (
                      <div className={styles.imgWrap} key={index}>
                        <img
                          loading="lazy"
                          onError={(event) => imageOnError(event)}
                          onClick={() => {
                            setBigImage(IMAGE_BASE_URL + imagesData.image);
                          }}
                          src={IMAGE_BASE_URL + imagesData.image}
                          className={styles.active}
                          alt="no-img"
                        />
                      </div>
                    )
                  )}
              </Grid>
            ) : (
              ""
            )}

            <Grid item lg={4} sm={8} xs={8} className={styles.imgBox}>
              <img
                loading="lazy"
                onError={(event) => imageOnError(event)}
                src={bigImage}
                className={styles.Productbig}
                alt="no-img"
              />
              {/* image here....! */}
            </Grid>

            {/* displaying for mobile view */}
            <div className={styles.productDetail_mob_view}>
              {singleProductData?.store_product_images?.length > 0 ? (
                <Grid
                  item
                  lg={2}
                  sm={4}
                  xs={4}
                  className={styles.custom_img_mob_view}
                >
                  {singleProductData &&
                    singleProductData.store_product_images.length > 0 &&
                    singleProductData.store_product_images.map(
                      (imagesData: any, index: number) => (
                        <div className={styles.imgWrap} key={index}>
                          <img
                            loading="lazy"
                            onError={(event) => imageOnError(event)}
                            onClick={() => {
                              setBigImage(IMAGE_BASE_URL + imagesData.image);
                            }}
                            src={IMAGE_BASE_URL + imagesData.image}
                            className={styles.active}
                            alt="no-img"
                          />
                        </div>
                      )
                    )}
                </Grid>
              ) : (
                ""
              )}
              <Grid
                item
                lg={4}
                sm={8}
                xs={8}
                className={styles.imgBox_mob_view}
              >
                <img
                  loading="lazy"
                  onError={(event) => imageOnError(event)}
                  src={bigImage}
                  className={styles.Productbig}
                  alt="no-img"
                />
                {/* image here....! */}
              </Grid>
            </div>

            {/* multiple images ends here */}

            <Grid item lg={6} sm={12} xs={12}>
              <div className={styles.productItemCard}>
                <h5>{singleProductData?.product_name ?? ""}</h5>
                <h3>
                  {singleProductData &&
                    `${
                      singleProductData.club_price === null
                        ? `$${(0).toFixed(2)}`
                        : `$${singleProductData.club_price.toFixed(2)}`
                    }`}
                </h3>

                {/* <p className={styles.cartQty}>
                  {productCartQty !== 0
                    ? `Currently in your cart - ${productCartQty}`
                    : ""}
                </p> */}

                <Grid container className={styles.customSelectBox}>
                  <Grid item xs={10} lg={5}>
                    <div className={styles.custom_filed}>
                      <Box component="form" noValidate autoComplete="off">
                        <InputField
                          isNotFormik={true}
                          name="quantity"
                          type="number"
                          label="Quantity"
                          valueData={qty}
                          placeholder="Enter quantity"
                          onChangeWork={(items) => {
                            changeQty(items.target.value);
                          }}
                          inputProps={{
                            max: parseInt(
                              singleProductData?.maximum_quantity ?? 1000
                            ),
                            min: parseInt(
                              singleProductData?.minimum_quantity ?? 1
                            ),
                          }}
                        />

                        {/* <TextField
                            style={{ margin: "6px 0px", width: "100%" }}
                            id="outlined-basic"
                            label="Enter quantity"
                            variant="outlined"
                          /> */}
                        <div className={styles.loseHigh}>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              if (qty > 1) {
                                changeQty(qty - 1);
                              }
                            }}
                            className={styles.lose}
                          >
                            -
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              changeQty(qty + 1);
                            }}
                            className={styles.High}
                          >
                            +
                          </button>
                        </div>
                      </Box>
                      {check && qtyError ? (
                        <span className={styles.error}>
                          Please enter quantity
                        </span>
                      ) : (
                        ""
                      )}
                      <span className={styles.error}>{error}</span>
                      <p className={styles.cartQty}>
                        {singleProductData?.minimum_quantity &&
                        singleProductData.minimum_quantity !== null
                          ? `${singleProductData.minimum_quantity} minimum `
                          : ""}{" "}
                        {singleProductData?.maximum_quantity &&
                        singleProductData.maximum_quantity !== null
                          ? ` - ${singleProductData.maximum_quantity} maximum`
                          : ""}
                      </p>
                    </div>
                  </Grid>
                  <Grid container>
                    {singleProductData?.store_options
                      ? singleProductData.store_options.map(
                          (optionsData: any, index: number) => {
                            const display_name =
                              singleProductData.store_product_options[index]
                                ?.display_name ?? optionsData.display_name;
                            if (optionsData.option_type === 1) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );

                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }

                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <SelectField
                                      label={display_name}
                                      // label="Size"
                                      value={selectedValue}
                                      name=""
                                      placeholder="Select Size"
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          singleProductData?.id,
                                          optionsData.id,
                                          items.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select size
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      isNotFormik={true}
                                      name=""
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(item) => {
                                        saveProduct(
                                          singleProductData?.id,
                                          optionsData.id,
                                          item.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      name=""
                                      isNotFormik={true}
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          singleProductData?.id,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {display_name}
                                        {/* Size */}
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(items) => {
                                          saveProduct(
                                            singleProductData &&
                                              singleProductData.id,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                      {check && selectedValue === "" ? (
                                        <span
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                            marginTop: "5px",
                                            display: "block",
                                            left: "1px",
                                          }}
                                        >
                                          Please select size
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </FormControl>
                                  </Grid>
                                );
                              }
                            } else if (optionsData.option_type === 2) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <SelectField
                                      label={display_name}
                                      // label="Color"
                                      name=""
                                      value={selectedValue}
                                      placeholder={`Select ${display_name}`}
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select color
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      isNotFormik={true}
                                      name=""
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      isNotFormik={true}
                                      name=""
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {display_name}
                                        {/* Color */}
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(items) => {
                                          saveProduct(
                                            singleProductData &&
                                              singleProductData.id,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              }
                            } else if (optionsData.option_type === 3) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );

                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <SelectField
                                      label={display_name}
                                      name=""
                                      value={selectedValue}
                                      placeholder={`Select ${display_name}`}
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      isNotFormik={true}
                                      name=""
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <InputField
                                      name=""
                                      isNotFormik={true}
                                      label={display_name}
                                      placeholder={`Enter ${display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          singleProductData &&
                                            singleProductData.id,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveOptopnList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid
                                    item
                                    xs={6}
                                    sx={{ padding: "5px" }}
                                    key={index}
                                  >
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {display_name}
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(items) => {
                                          saveProduct(
                                            singleProductData &&
                                              singleProductData.id,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {check && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select{" "}
                                        <span
                                          style={{
                                            textTransform: "lowercase",
                                            color: "red",
                                          }}
                                        >
                                          {display_name}
                                        </span>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              }
                            }
                          }
                        )
                      : "1"}
                    {/* <div>
                      <SelectField
                        name="color"
                        label="Color"
                        placeholder="Select Color"
                        options={[
                          { title: "Team 1", value: "team1" },
                          { title: "Team 1", value: "team2" },
                        ]}
                      />
                    </div> */}
                  </Grid>
                  {/* <Grid xs={5}>
                    <div>
                      <SelectField
                        name="size"
                        label="Size"
                        placeholder="Select Size"
                        options={[
                          { title: "Team 1", value: "team1" },
                          { title: "Team 1", value: "team2" },
                        ]}
                      />
                    </div>
                  </Grid> */}
                </Grid>

                <div className={styles.addToCartWrap}>
                  <AppButton
                    onClick={addToCart}
                    disabled={
                      !!error ||
                      disableButton ||
                      (singleProductData &&
                      singleProductData.maximum_quantity !== null &&
                      singleProductData.maximum_quantity <= productCartQty
                        ? true
                        : false || bundleErros)
                    }
                    variant="contained"
                    color={
                      saveOptopnList.filter((product: any) => {
                        return product.value === "";
                      }).length > 0 ||
                      !!error ||
                      disableButton ||
                      (singleProductData &&
                      singleProductData.maximum_quantity !== null &&
                      singleProductData.maximum_quantity <= productCartQty
                        ? true
                        : false || bundleErros)
                        ? "primary"
                        : "success"
                    }
                  >
                    Add to Cart
                  </AppButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      {strip_html_tags(singleProductData?.description ?? "").trim() ? (
        <>
          <div className={styles.productDetailList}>
            <h4>Description</h4>
            <p
              className={styles.productDetail_description}
              // style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: singleProductData && singleProductData.description,
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProductDetailCard;
