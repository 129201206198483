import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import styles from "../../scss/common/common.module.scss";
import AppButton from "../theme/Button";
import LoadingBars from "./LoadingBars";
import BundleItem from "./bundleItem";
import { Decrypt, Encrypt } from "../utils/Functions";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1280,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "70vh",
  overflow: "auto",
  maxWidth: "100%",
  border: "1px solid #E6E8ED",
  borderRadius: "8px",
};
interface IProps {
  cart: any;
  open: any;
  handleOpen: any;
  handleClose: any;
  title: any;
  subtitle: any;
  size: any;
  price: any;
  checkoutPage?: boolean;
  productLoading?: boolean;
}
const BundleItemModal = ({
  cart,
  open,
  handleOpen,
  handleClose,
  title,
  subtitle,
  size,
  price,
  checkoutPage = false,
  productLoading,
}: IProps) => {
  const [bundleErros, setBundleErros] = useState<number[]>([]);
  const changeQuantity = (productId: number, quantity: any) => {
    if (!cart?.isSingleProduct) {
      const product = cart?.bundle_products.findIndex(
        (product: any) => product.id === productId
      );
      if (product >= 0) {
        cart.bundle_products[product].store_product_detail.quantity = quantity;
      }
    } else {
      if (cart?.id === productId) {
        cart.quantity = quantity;
      }
    }
  };

  const saveChanges = () => {
    let newCartData = [];
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

    if (cartOldData && cartOldData.length > 0) {
      newCartData = cartOldData;
      const cartItemIndex = newCartData.findIndex(
        (cartProduct: any) => cartProduct.id === cart?.id
      );
      newCartData[cartItemIndex] = cart;
    }
    // newCartData.push(newCartData)
    const cartData = JSON.stringify(newCartData);
    const cartDataEn = Encrypt(cartData);
    localStorage.setItem("cart", cartDataEn);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          setBundleErros([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.shoppingItems}>
          <div className={styles.bundleModal_heading}>
            <Typography
              className={styles.modalTitle}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {!cart?.isSingleProduct
                ? `Bundle Items (${
                    cart &&
                    cart?.bundle_products &&
                    cart?.bundle_products.length
                  })`
                : `Item (1)`}
            </Typography>

            <div className={styles.bundleModal_btns}>
              {checkoutPage ? (
                <AppButton
                  className={styles.itemModalBtnClose}
                  onClick={handleClose}
                >
                  Close
                </AppButton>
              ) : (
                <>
                  <AppButton
                    className={styles.itemModalBtnCencel}
                    onClick={handleClose}
                  >
                    Cancel
                  </AppButton>
                  <AppButton
                    className={styles.itemModalBtnSave}
                    onClick={saveChanges}
                    disabled={bundleErros?.length > 0}
                  >
                    Save Changes
                  </AppButton>
                </>
              )}
            </div>
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {productLoading ? (
              <LoadingBars />
            ) : (
              <div className={styles.itemModal}>
                {cart &&
                  !cart?.isSingleProduct &&
                  cart?.bundle_products &&
                  cart?.bundle_products.map((item: any, index: number) => (
                    <BundleItem
                      key={index}
                      product={item}
                      changeQuantity={changeQuantity}
                      checkoutPage={checkoutPage}
                      bundleErros={bundleErros}
                      setBundleErros={setBundleErros}
                    />
                  ))}
                {cart && cart?.isSingleProduct && (
                  <BundleItem
                    product={cart}
                    changeQuantity={changeQuantity}
                    checkoutPage={checkoutPage}
                    bundleErros={bundleErros}
                    setBundleErros={setBundleErros}
                  />
                )}
              </div>
            )}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default BundleItemModal;
