import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";

const useFetch = (
  url: string,
  config?: AxiosRequestConfig
): [
  (data?: any, rest?: AxiosRequestConfig) => Promise<AxiosResponse<any>>,
  {
    response: any;
    loading: boolean;
    error: AxiosError;
    errorMessage: string;
    resetResponse: () => void;
  },
] => {
  const [response, setResponse] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  const loadQuery = async (data?: any, rest?: AxiosRequestConfig) => {
    const headers = {};

    return new Promise<AxiosResponse<any>>((resolve, reject) => {
      setLoading(true);
      instance({
        url: `${url}`,
        ...config,
        data,
        headers,
      })
        .then((response: AxiosResponse) => {
          console.log(response.data, "then");

          if (response.status === 200 || response.status === 201) {
            resolve(response);
            setError(undefined);
            response.data != null && setResponse(response.data);
          } else {
            setError(response?.data);
            setErrorMessage(response?.data?.message ?? "Something went wrong!");
            setResponse(undefined);
          }
          setLoading(false);
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 401 || e.response?.status === 403) {
            navigate("/");
          } else if (e.response?.status === 404 || e.response?.status === 400) {
            setResponse(e.response.data);
            setError(undefined);
            // setErrorMessage(response?.data?.message ?? "Something went wrong!");
          } else {
            const errorData: any = e.response?.data;
            if (errorData?.status === 2) {
              setResponse(errorData);
              setError(undefined);
            } else {
              setResponse(undefined);
            }
          }
          setErrorMessage(
            e.response?.data?.toString() ?? "Something went wrong!"
          );
          setError(e.response?.data);
          setLoading(false);
        });
    });
  };

  const resetResponse = () => {
    setResponse(undefined);
  };

  return [loadQuery, { response, loading, error, errorMessage, resetResponse }];
};

export default useFetch;
