import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DIRECT_ACCESS_URL, IMAGE_BASE_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import { IStoreDesignData } from "../../interfaces/StoreInterface";
import { setStoreData } from "../../redux/StoreData/StoreDataSlice";
import { setStoreDesignListSlice } from "../../redux/StoreDesignList/StoreDesignListSlice";
import styles from "../../scss/layout/layout.module.scss";
import Header from "../sections/Header";
import StoreExpired from "../../pages/storeExpired";
import Banner from "../sections/Banner";
import BundleDetailBanner from "../../pageComponents/bundleDetail/bundleDetailbanner";
import TimerSection from "../sections/TimerSection";
import ContactDetailSection from "../sections/ContactDetailSection";
import ImageBoxSection from "../sections/ImageBoxSection";
import FooterSection from "../sections/FooterSection";
import ComingSoon from "../../pages/comingSoon";
import StoreNotFound from "../../pages/storeNotFound";
interface IProps {
  children: ReactNode;
  isHomePage?: boolean;
  pageTitle?: string;
}

const Layout = ({ children, isHomePage, pageTitle }: IProps) => {
  const dispatch = useDispatch();
  const [loader, setloader] = useState(true);
  const [expired, setExpired] = useState(false);
  const [commingPage, setCommingPage] = useState(false);
  const [storeNotFound, setStoreNotFoundPage] = useState(false);
  // const [openDate, setOpenDate] = useState<any>(null);
  const [storeDesignData, setStoreDesignData] =
    useState<IStoreDesignData | null>(null);
  /** Get direct access url */
  const direct_access_url = DIRECT_ACCESS_URL;
  /** Api call function start */

  // use for local test
  // const [getStoreInfo, { response: getStorInfoRes, error: getStorInfoError }] =
  // useFetch(`/get-store-info/store-one`, {
  //   method: "get",
  // });

  const [
    getStoreInfo,
    { response: getStorInfoRes, error: getStorInfoError, loading },
  ] = useFetch(`/get-store-info/${direct_access_url}`, {
    method: "get",
  });
  /** Api call function end */
  useEffect(() => {
    getStoreInfo();
  }, []);
  useEffect(() => {
    /** Checks For handle store info response  */
    if (getStorInfoRes) {
      if (getStorInfoRes?.status) {
        dispatch(setStoreData(getStorInfoRes?.data));
        if (
          getStorInfoRes?.data?.open_date_time &&
          new Date(getStorInfoRes?.data?.open_date_time * 1000) > new Date()
        ) {
          setCommingPage(true);
          // setOpenDate(getStorInfoRes?.data?.open_date_time);

          // localStorage.setItem(
          //   getStorInfoRes?.data?.open_date_time,
          //   "openDate"
          // );
          // navigate("/coming-soon", {
          //   state: getStorInfoRes?.data?.open_date_time,
          // });
        } else if (getStorInfoRes?.data?.close_date_time) {
          const currentDate = moment();
          const closeDateTm = moment.unix(
            getStorInfoRes?.data?.close_date_time
          );
          if (currentDate.diff(closeDateTm, "milliseconds") > 0) {
            setExpired(true);
          }
        } else if (getStorInfoRes?.data?.status === 2) {
          setExpired(true);
        } else if (getStorInfoRes?.status && getStorInfoRes?.code === 404) {
          setStoreNotFoundPage(true);
        }

        dispatch(
          setStoreDesignListSlice({
            ...getStorInfoRes?.data?.store_design_settings,
            is_team_available: getStorInfoRes?.data?.is_team_available,
          })
        );
        setStoreDesignData(getStorInfoRes?.data);
        setloader(false);
      }
    } else if (getStorInfoError) {
      dispatch(setStoreData({ data: {} }));
      setStoreNotFoundPage(true);
      // navigate("/store-not-found");
    }
  }, [getStorInfoRes, getStorInfoError]);

  return (
    <>
      {getStorInfoRes && !commingPage && !storeNotFound ? (
        <div className={styles.layout}>
          <Header
            headerOnly={expired}
            logo={storeDesignData?.store_design_settings?.club_logo}
            data={storeDesignData?.store_design_settings}
            loading={loading}
          />
          {expired ? (
            <div className={styles.expireStore_wrap}>
              <StoreExpired />
            </div>
          ) : (
            <>
              {isHomePage ? (
                <Banner
                  image={
                    !loader
                      ? storeDesignData !== null &&
                        storeDesignData?.store_design_settings !== null &&
                        storeDesignData?.store_design_settings?.banner_image !==
                          null &&
                        storeDesignData?.store_design_settings?.banner_image !==
                          ""
                        ? `${IMAGE_BASE_URL}${storeDesignData?.store_design_settings?.banner_image}`
                        : "/images/templateBanner.jpg"
                      : ""
                  }
                />
              ) : (
                <>
                  <BundleDetailBanner
                    pageTitle={pageTitle}
                    image={
                      storeDesignData !== null &&
                      storeDesignData?.store_design_settings !== null &&
                      storeDesignData?.store_design_settings?.banner_image !==
                        null &&
                      storeDesignData?.store_design_settings?.banner_image !==
                        ""
                        ? `${IMAGE_BASE_URL}${storeDesignData?.store_design_settings?.banner_image}`
                        : "/images/templateBanner.jpg"
                    }
                  />
                </>
              )}
              {storeDesignData !== null && (
                <TimerSection timeSecData={storeDesignData} />
              )}

              {children}
              {storeDesignData !== null && storeDesignData?.customer_name ? (
                <ContactDetailSection contactData={storeDesignData} />
              ) : (
                ""
              )}

              {isHomePage &&
              storeDesignData !== null &&
              storeDesignData?.store_design_settings
                ?.is_promise_section_show !== 2 ? (
                <ImageBoxSection
                  heading={
                    storeDesignData !== null &&
                    storeDesignData?.store_design_settings !== null &&
                    storeDesignData?.store_design_settings?.promise_title !==
                      null
                      ? storeDesignData?.store_design_settings?.promise_title
                      : "Our Promise"
                  }
                  description={
                    storeDesignData !== null &&
                    storeDesignData?.store_design_settings !== null &&
                    storeDesignData?.store_design_settings
                      ?.promise_description !== null
                      ? storeDesignData?.store_design_settings
                          ?.promise_description
                      : "We aren't just selling a product. We are selling an experience. A shared, collaborative experience in which we thrive together to create the best."
                  }
                  image="/images/ourPromiseBg.jpg"
                />
              ) : (
                ""
              )}
              <FooterSection
                logo={storeDesignData?.store_design_settings?.club_logo}
                data={storeDesignData?.store_design_settings}
              />
            </>
          )}
        </div>
      ) : commingPage ? (
        <ComingSoon openDate={getStorInfoRes?.data?.open_date_time} />
      ) : storeNotFound ? (
        <StoreNotFound />
      ) : (
        ""
      )}
    </>
  );
};
export default Layout;
