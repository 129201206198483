import "react-quill/dist/quill.snow.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scss/App.scss";
// import Home from "./pages";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCartCount } from "./redux/cart";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ErrorBoundary } from "react-error-boundary";
// import { RotatingLines } from "react-loader-spinner";
import Home from "./pages";
import BundleDetail from "./pages/bundleDetail";
import BundleCart from "./pages/bundleCart";
import StoreNotFound from "./pages/storeNotFound";
import ComingSoon from "./pages/comingSoon";
import CheckoutCart from "./pages/CheckoutCart";
import ProductDetail from "./pages/productDetail";
import StoreExpired from "./pages/storeExpired";
import ThankYou from "./pages/thankYou";
import { Decrypt } from "./common/utils/Functions";
import { BUILD_VERSION } from "./constants/constants";

let stripePromise: Stripe | PromiseLike<Stripe | null> | null;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY || "");
  }
  return stripePromise;
};
function ErrorFallback({ componentStack, error }: any) {
  return (
    <section className="fallback">
      <header className="fallback__header">
        <h3 className="fallback__title">Oops! An error occured!</h3>
      </header>
      <div className="fallback__body">
        <p>
          <strong>Error:</strong> {error.toString()}
        </p>
        <p>
          <strong>Stacktrace:</strong> {componentStack}
        </p>
      </div>
    </section>
  );
}

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        const cartDataInfoD = localStorage?.getItem("cart");
        if (cartDataInfoD) {
          const cartDataInfo = Decrypt(cartDataInfoD);
          if (cartDataInfo) {
            const cartData = JSON.parse(cartDataInfo);
            console.log(cartData, "cartDataInfo");
            if (cartData) {
              dispatch(setCartCount(cartData.length));
            }
          }
        }
      } catch (error) {
        console.error("Error accessing localStorage or parsing JSON:", error);
      }
    }
  }, [dispatch]);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready
        .then(() => navigator.serviceWorker.getRegistrations())
        .then((registrations) => {
          registrations.forEach((registration) => registration.unregister());
        })
        .catch((error) => {
          if (error.name === "SecurityError") {
            console.error(
              "Service Worker unregistration failed due to insecure context."
            );
          } else {
            console.error("Service Worker unregistration failed:", error);
          }
        });
    }
  }, []);
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const latestVersion = String(BUILD_VERSION);
        const currentVersion = localStorage?.getItem("appVersion");
        if (currentVersion) {
          if (currentVersion !== latestVersion) {
            localStorage?.setItem("appVersion", latestVersion);
            window.location.reload();
          }
        } else {
          localStorage?.setItem("appVersion", latestVersion);
          window.location.reload();
        }
      } catch (error) {
        console.error("Error checking version:", error);
      }
    };

    checkVersion();
  }, []);

  return (
    <div className="app">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Elements stripe={getStripe()}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bundle-detail/:id" element={<BundleDetail />} />
            {/* <Route path="/bundle-detail" element={<BundleDetail />} /> */}
            <Route path="/cart" element={<BundleCart />} />
            <Route path="/checkout-cart" element={<CheckoutCart />} />
            <Route path="/thankyou" element={<ThankYou />} />
            <Route path="/store-not-found" element={<StoreNotFound />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/product-detail/:id" element={<ProductDetail />} />
            <Route path="/store-expired" element={<StoreExpired />} />
          </Routes>
          <ToastContainer />
        </Elements>
      </ErrorBoundary>
    </div>
  );
}

export default App;
