import React from "react";
import styles from "../../scss/common/common.module.scss";
import { cartIcon } from "../../assets/Icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../constants/constants";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";
import AppContainer from "../theme/Container";
import AppButton from "../theme/Button";

interface IProps {
  headerOnly?: boolean;
  logo: string | undefined | null;
  data: any;
  loading?: boolean;
}
const Header = ({ headerOnly, logo, data, loading }: IProps) => {
  const cartCount = useSelector((state: any) => state.cartDataSlice.cartCount);
  const storeData = useSelector((state: any) => state.storeDataSlice.data);

  const navigate = useNavigate();
  const colorStatus: any = data?.is_color_status;
  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor:
        colorStatus === 2 && data?.primary_color
          ? `${data?.primary_color}`
          : "#888889",
    },
  };
  return (
    <header className={`${styles.header} ${styles.headerOnly}`}>
      <AppContainer>
        <div className={styles.header_inner}>
          <div className={styles.logo} onClick={() => navigate("/")}>
            {!loading ? (
              logo !== "" && logo !== undefined && logo !== null ? (
                <img
                  loading="lazy"
                  src={`${IMAGE_BASE_URL}${logo}`}
                  alt="img"
                />
              ) : (
                <img loading="lazy" src="/images/logo.svg" alt="img" />
              )
            ) : (
              ""
            )}
          </div>
          <div className={styles.storeName}>{storeData?.store_name}</div>
          {!headerOnly ? (
            <div className={styles.cartIcon}>
              <Link to="/cart">
                <Badge badgeContent={cartCount} sx={badgeStyle}>
                  <AppButton variant="outlined" btnicon={true}>
                    {cartIcon}
                  </AppButton>
                </Badge>
              </Link>
            </div>
          ) : (
            <div className={styles.emptyDiv}></div>
          )}
        </div>
      </AppContainer>
    </header>
  );
};

export default Header;
