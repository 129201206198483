import React from "react";
import Layout from "../common/layouts/Layout";
import AppContainer from "../common/theme/Container";
import ThankYouCart from "../pageComponents/thankYou/thankYouCard";

const ThankYou = () => {
  return (
    <>
      <Layout pageTitle="thankyou">
        <AppContainer>
          <ThankYouCart />
        </AppContainer>
      </Layout>
    </>
  );
};

export default ThankYou;
