/* eslint-disable no-nested-ternary */
import { Container, ContainerProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    "&.MuiContainer-maxWidthLg": {
      maxWidth: "1328px",
    },
  },
});

const AppContainer = (props: ContainerProps) => {
  const classes = useStyles();
  return <Container className={classes.container} {...props} />;
};
export default AppContainer;
