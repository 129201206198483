import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../scss/pageComponent/home.module.scss";
import AppContainer from "../../common/theme/Container";

const ShopOptionalSection = () => {
  const [data, setData] = useState<any>({});
  const storeDesignSettingData = useSelector(
    (state: any) => state.storeDesignList
  );
  useEffect(() => {
    if (storeDesignSettingData) {
      setData(storeDesignSettingData?.data);
    }
  }, [storeDesignSettingData]);

  return (
    <>
      <section className={styles.shopOptional_section}>
        <AppContainer>
          <div className={styles.wrapper}>
            <div
              className={styles.image}
              style={{ backgroundImage: "url(/images/optSecImg.jpg)" }}
            >
              {/* <img loading="lazy" src="/images/optSecImg.jpg" alt="img" /> */}
            </div>
            <div className={styles.right_content}>
              <div className={styles.content_box}>
                <div className={styles.content}>
                  <div className={styles.heading}>
                    <h2>
                      {data?.vector_image_title
                        ? data?.vector_image_title
                        : "Shop Optional Items"}
                    </h2>
                    <p>
                      {data?.vector_image_description
                        ? data?.vector_image_description
                        : "Shop Our New Clean Elevation Optional Items"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppContainer>
      </section>
    </>
  );
};

export default ShopOptionalSection;
