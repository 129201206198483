import React from "react";
import Layout from "../common/layouts/Layout";
import AppContainer from "../common/theme/Container";
import CheckoutCartCard from "../pageComponents/CheckoutCart/CheckoutCart";

const CheckoutCart = () => {
  return (
    <Layout pageTitle={"checkout"}>
      <AppContainer>
        <CheckoutCartCard />
      </AppContainer>
    </Layout>
  );
};

export default CheckoutCart;
