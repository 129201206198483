/* eslint-disable no-nested-ternary */
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { addIcon } from "../../assets/Icons/icons";
import AppButton from "./Button";

interface IInputProps {
  formik?: any;
  addFieldPlaceholder?: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  options: { title: string; value: string }[];
  addField?: boolean;
  handleAddField?: (value: string) => void;
  handleEdit?: (value: { id: string; name: string }) => void;
  handleDelete?: () => void;
  required?: boolean;
  loader?: boolean;
  Deleteloader?: boolean;
  setId?: (value: string) => void;
  response?: any;
  isReset?: boolean;
  isEditTable?: boolean;
  selectedValue?: ({ value, title }: { value: string; title: string }) => void;
  value?: string;
  disabled?: boolean;
}

const useStyles = makeStyles({
  formSelect: {
    // backgroundColor: "#000",
  },
});

export const SelectField = ({
  formik,
  name,
  addFieldPlaceholder,
  label,
  placeholder,
  options,
  addField,
  handleAddField,
  handleEdit,
  handleDelete,
  required,
  loader,
  Deleteloader,
  setId,
  response,
  isReset,
  isEditTable,
  selectedValue,
  value,
  disabled,
}: IInputProps) => {
  const classes = useStyles();
  const [addFieldPop, setAddFieldPop] = useState(false);
  const [addInputValue, setInputValue] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [addOrganizationError, setAddOrganizationError] = useState(false);

  const handleToggleAddPop = () => {
    setAddFieldPop(!addFieldPop);
  };
  const handleAddInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setAddOrganizationError(false);
  };

  useEffect(() => {
    if (!loader) {
      setInputValue("");
      setSelectedId("");
      setAddFieldPop(false);
    }
  }, [loader]);
  useEffect(() => {
    if (!Deleteloader) {
      setInputValue("");
      setSelectedId("");
      setAddFieldPop(false);
    }
  }, [Deleteloader]);

  useEffect(() => {
    if (response?.status) {
      handleToggleAddPop();
      setInputValue("");
    }
  }, [response]);

  const selectComponent = () => (
    <div className="input_field_wrap">
      <FormControl size="small" fullWidth>
        <Select
          fullWidth
          className={`${classes.formSelect} form_select ${
            formik?.values[name] === "" ? "placeholderSelected" : ""
          }`}
          value={
            value
              ? value
              : formik?.values[name] === undefined
              ? ""
              : formik?.values[name]
          }
          name={name}
          onChange={formik?.handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{ className: "form_select_list" }}
          IconComponent={KeyboardArrowDown}
          error={formik?.touched[name] && formik?.errors[name]}
          defaultValue={formik?.values[name] ? formik?.values[name] : ""}
          disabled={disabled}
        >
          {placeholder && (
            <MenuItem disabled value="" style={{ opacity: "0.75" }}>
              {placeholder}
            </MenuItem>
          )}
          {isReset && <MenuItem value="none">None</MenuItem>}

          {options.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => {
                formik?.setFieldValue(name, item.value);
                selectedValue && selectedValue(item);
              }}
            >
              <div className="options_content">
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  {item.title}
                </div>
                {isEditTable ? (
                  <div
                    className="edit_link"
                    onClick={(event) => {
                      setId && setId(item?.value ?? "");
                      setInputValue(item.title);
                      setSelectedId(item.value);
                      handleToggleAddPop();
                    }}
                  >
                    Edit
                  </div>
                ) : (
                  ""
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/*  */}
      <div className="error">
        {formik?.touched[name] && formik?.errors[name]}
      </div>
    </div>
  );

  return (
    <div className="form_control">
      {label && (
        <InputLabel
          className="form_label"
          htmlFor={name}
          disabled={disabled}
          // style={{ textTransform: "capitalize" }}
        >
          {label}
          {required && <sup>*</sup>}
        </InputLabel>
      )}
      {addField ? (
        <div className="add_select_wrap">
          {selectComponent()}
          <AppButton
            variant="text"
            className="addBtn"
            onClick={handleToggleAddPop}
          >
            {addIcon}
          </AppButton>
          {addFieldPop && (
            <ClickAwayListener onClickAway={handleToggleAddPop}>
              <div className="add_select_menu">
                <div className="input_wrap">
                  <input
                    disabled={disabled}
                    type="text"
                    value={addInputValue}
                    placeholder={addFieldPlaceholder}
                    onChange={handleAddInput}
                  />
                </div>
                {addOrganizationError && (
                  <p>
                    <span className="red">
                      Please enter your organization name
                    </span>
                  </p>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <AppButton
                      disabled={addInputValue.trim() ? false : true}
                      onClick={() => {
                        if (selectedId) {
                          if (handleEdit) {
                            handleEdit({
                              id: selectedId,
                              name: addInputValue,
                            });
                          } else {
                            setAddOrganizationError(true);
                          }
                        } else {
                          if (handleAddField) {
                            handleAddField(addInputValue);
                          } else {
                            setAddOrganizationError(true);
                          }
                        }
                      }}
                    >
                      {loader && (
                        <span className="loadingBars">
                          <RotatingLines
                            strokeColor="currentColor"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                          />
                        </span>
                      )}
                      {selectedId ? "Update" : "Add"}
                    </AppButton>
                  </div>
                  <div>
                    {selectedId ? (
                      <AppButton
                        disabled={addInputValue ? false : true}
                        onClick={() => {
                          if (selectedId) {
                            if (handleDelete) {
                              handleDelete();
                            }
                          }
                        }}
                      >
                        {Deleteloader && (
                          <span className="loadingBars">
                            <RotatingLines
                              strokeColor="currentColor"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="20"
                            />
                          </span>
                        )}
                        Delete
                      </AppButton>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </div>
      ) : (
        selectComponent()
      )}
    </div>
  );
};
