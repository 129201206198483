import { InputField } from "../../common/theme/Input";
import { useFormik } from "formik";
import styles from "../../scss/common/common.module.scss";
import Grid from "@mui/material/Grid";
import { shippingMethodForm } from "../../validations/CheckoutSchema";
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutSecondFormSlice } from "../../redux/CheckoutForm/CheckoutFormSlice";
import React, { useEffect, useState } from "react";
import useFetch from "../../customHook/useFetch";
import { toast } from "react-toastify";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import AppButton from "../../common/theme/Button";
import LoadingBars from "../../common/sections/LoadingBars";
const _ = require("lodash");

interface IProps {
  selected?: any;
  formInfo?: any;
  ShowSpecificForm?: any;
  billingAddress?: boolean;
  isLoading?: boolean;
  placeOrder: (first: any, shipping: any) => void;
  setSummaryData: any;
  summaryData: any;
  isCollectTax: boolean;
}

interface clubStateListApi {
  id: number;
  state_name: string;
}

const CheckoutShipmentForm = ({
  selected,
  formInfo,
  ShowSpecificForm,
  billingAddress,
  isLoading,
  placeOrder,
  setSummaryData,
  summaryData,
  isCollectTax,
}: IProps) => {
  const dispatch = useDispatch();

  const [selectedFilter, setSelectedFilter] = useState<any>({
    label: "",
    value: 0,
  });
  const [clubStateList, setClubStateList] = useState([]);
  const [formValues, setFormValues] = useState<any>();
  const [placeOrderDisable, setPlaceOrderDisable] = useState(true);
  const [
    getClubStateList,
    { response: getClubStateResponse, error: getClubStateError },
  ] = useFetch("/us-states/list?start=0&limit=-1", {
    method: "get",
  });

  const storeDesignSettingData = useSelector(
    (state: any) => state.storeCheckoutForm.SecondFormSlice
  );

  useEffect(() => {
    if (storeDesignSettingData?.state) {
      setSelectedFilter(
        clubStateList?.find(
          (item: any) => item?.value === storeDesignSettingData?.state
        )
      );
    }
  }, [clubStateList]);

  const [taxAutomatically, setTaxAutomatically] = useState<number | null>(null);

  /** Get tax api calling start */
  const [getTaxApi, { loading: taxLoading }] = useFetch(
    `/get-tax/${formValues?.zipCode}`,
    {
      method: "get",
    }
  );
  const callGetTaxApi = async () => {
    const response = await getTaxApi();
    if (response?.data?.status && response?.data?.data) {
      setSummaryData({
        ...summaryData,
        tax:
          summaryData.tax_type === 1
            ? Number(response?.data?.data?.combined_rate)
            : summaryData.tax,
      });
      setTaxAutomatically(Number(response?.data?.data?.combined_rate));
      setPlaceOrderDisable(false);
    } else {
      toast.error("Invalid zip code");
    }
  };

  const formik: any = useFormik({
    initialValues: {
      firstName: storeDesignSettingData.firstName,
      lastName: storeDesignSettingData.lastName,
      street: storeDesignSettingData.street,
      aptUnit: storeDesignSettingData.aptUnit,
      state: storeDesignSettingData.state,
      zipCode: storeDesignSettingData.zipCode,
      city: storeDesignSettingData.city,
    },
    validationSchema: shippingMethodForm,
    onSubmit: (values) => {
      if (isCollectTax) {
        if (values?.zipCode === formValues?.zipCode) {
          // setSummaryData((pre: any) => ({ ...pre, tax: taxAutomatically }));
          dispatch(setCheckoutSecondFormSlice(formValues));
          if (ShowSpecificForm?.PaymentForm === true) {
            selected(2);
          }
        } else {
          setFormValues(values);
        }
      } else {
        dispatch(setCheckoutSecondFormSlice(values));
        if (ShowSpecificForm?.PaymentForm === false) {
          // Api will hit
          placeOrder(null, values);
        } else {
          selected(2);
        }
      }
    },
  });

  useEffect(() => {
    if (taxAutomatically !== null) {
      // setSummaryData((pre: any) => ({ ...pre, tax: taxAutomatically }));
      dispatch(setCheckoutSecondFormSlice(formValues));
      // setFormValues({});
      // setTaxAutomatically(0);
      if (ShowSpecificForm?.PaymentForm === true) {
        selected(2);
      }
    }
  }, [taxAutomatically]);

  useEffect(() => {
    if (formValues?.zipCode) {
      callGetTaxApi();
    }
  }, [formValues]);

  useEffect(() => {
    getClubStateList();
  }, []);

  useEffect(() => {
    if (getClubStateResponse?.status) {
      const options = getClubStateResponse?.data?.list.map(
        (data: clubStateListApi) => ({
          label: data?.state_name,
          value: data?.id,
        })
      );
      if (options.length) setClubStateList(options.reverse());
    } else {
      toast.error(getClubStateError?.message);
    }
  }, [getClubStateResponse, getClubStateError]);

  useEffect(() => {
    if (_.isEqual(formValues, formik?.values)) {
      setPlaceOrderDisable(false);
    } else {
      setPlaceOrderDisable(true);
    }
  }, [formik?.values]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className={styles.CheckoutCart}>
          <Grid item lg={6} xs={12}>
            <InputField
              required
              formik={formik}
              label="First Name"
              placeholder="Enter first name"
              name="firstName"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              required
              formik={formik}
              label="Last Name"
              placeholder="Enter last name"
              name="lastName"
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              required
              formik={formik}
              label="Street"
              placeholder="Enter street"
              name="street"
              // disabled={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              formik={formik}
              label="Apt/Unit"
              placeholder="Enter apt/unit"
              name="aptUnit"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField
              required
              formik={formik}
              label="City"
              placeholder="Enter city"
              name="city"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            {/* <SelectField
              required
              label="State"
              name="state"
              placeholder="Select state"
              formik={formik}
              options={clubStateList}
            /> */}
            <div className={`form_control statusWrap`}>
              <InputLabel className="form_label">
                State<sup>*</sup>
              </InputLabel>
              <Autocomplete
                options={clubStateList}
                className={`form_select`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select state" />
                )}
                classes={{
                  popper: "customPopper",
                }}
                value={selectedFilter}
                onChange={(event, values) => {
                  setSelectedFilter(values);
                  formik?.setFieldValue("state", values?.value || "");
                }}
              />
              <div className="error">
                {formik?.touched.state && formik?.errors.state}
              </div>
            </div>
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              required
              formik={formik}
              label="Zip Code"
              placeholder="Enter zip code"
              name="zipCode"
            />
          </Grid>
        </Grid>

        <div className={styles.shoppingChat_checkoutBtn}>
          {ShowSpecificForm?.PaymentForm === true && (
            <AppButton
              type="submit"
              className={styles.continue}
              // onClick={GoToNextPage}
              disabled={taxLoading}
            >
              Save & Continue {taxLoading && <LoadingBars />}
            </AppButton>
          )}

          {ShowSpecificForm?.PaymentForm === false && (
            <>
              {isCollectTax && (
                <AppButton
                  type="submit"
                  className={styles.continue}
                  disabled={isLoading}
                >
                  Save
                </AppButton>
              )}

              <AppButton
                className={styles.continue}
                disabled={
                  isCollectTax &&
                  (isLoading || taxAutomatically === null || placeOrderDisable)
                }
                onClick={() => placeOrder(null, formik.values)}
              >
                Place Order
              </AppButton>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default CheckoutShipmentForm;
