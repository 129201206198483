import React, { useState } from "react";
import ShoppingCards from "../pageComponents/bundleCart/shoppingCards";
import BundleCartShoppingCard from "../pageComponents/bundleCart/bundleCartShoppingCard";
import AppContainer from "../common/theme/Container";
import Layout from "../common/layouts/Layout";

const BundleCart = () => {
  const [product_id_set, set_product_id_set] = useState<any>(new Set());

  return (
    <Layout pageTitle="cart">
      <AppContainer>
        <BundleCartShoppingCard
          product_id_set={product_id_set}
          set_product_id_set={set_product_id_set}
        />
        <ShoppingCards product_id={[...product_id_set]} />
        {/* <ShoppingProductEmpty /> */}
      </AppContainer>
    </Layout>
  );
};

export default BundleCart;
