/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  ClickAwayListener,
  InputLabel,
  Popper,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addIcon,
  checkboxCheckedIcon,
  checkboxUncheckedIcon,
} from "../../assets/Icons/icons";
import ThemeCheckbox from "./checkbox";
import styles from "../../scss/common/theme.module.scss";
import { Close } from "@mui/icons-material";
import AppButton from "./Button";
interface IInputProps {
  formik?: any;
  addFieldPlaceholder?: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  options: { title: string; value: string }[];
  addField?: boolean;
  handleAddField?: (value: string) => void;
  handleDelete?: (index: any) => void;
  noCheckList?: boolean;
  required?: boolean;
  value?: any;
  setSelectedValue?: (value: any) => void;
  limitTags?: number;
  handleTeamChange?: any;
  handleClose?: any;
}

export const MultiSelect = ({
  formik,
  name,
  addFieldPlaceholder,
  label,
  placeholder,
  options,
  addField,
  handleAddField,
  noCheckList,
  required,
  value,
  setSelectedValue,
  limitTags = 2,
  type,
  handleTeamChange,
  handleClose,
}: IInputProps) => {
  const [addFieldPop, setAddFieldPop] = useState(false);
  const [addInputValue, setInputValue] = useState("");
  const [addFieldError, setAddFieldError] = useState(false);
  const [selectedValues, setSelectedValues] = useState<
    { title: string; value: string }[]
  >([]);

  useEffect(() => {
    const unique2 = value?.filter((obj: any, index: number) => {
      return (
        index ===
        value?.findIndex(
          (o: any) => obj.value === o.value && obj.title === o.title
        )
      );
    });
    if (value !== undefined) {
      setSelectedValues(unique2);
    }
  }, [value]);

  const handleToggleAddPop = () => {
    setAddFieldPop(!addFieldPop);
  };
  const handleAddInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const autoComplete = () => (
    <Autocomplete
      // onClose={handleClose}
      multiple
      limitTags={limitTags}
      // className="form_select "
      className={type === "twoRow" ? "form_select heightLess" : "form_select"}
      options={options}
      value={selectedValues}
      onChange={(event, values) => {
        setSelectedValue !== undefined
          ? setSelectedValue(values)
          : setSelectedValues(values);
      }}
      disableCloseOnSelect
      disableClearable
      PopperComponent={({ anchorEl, children, ...props }) => (
        <Popper
          {...props}
          anchorEl={anchorEl}
          placement="bottom-start"
          // className="form_select_list"
          className={
            type === "twoRow" ? "form_select_list two-row" : "form_select_list"
          }
        >
          {children}
        </Popper>
      )}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) =>
        noCheckList ? (
          <li {...props} className={styles.multiSelectList_item}>
            <span className={styles.content}>{option.title}</span>
            <span
              className={styles.close}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Close />
            </span>
          </li>
        ) : (
          <li {...props} className="checkboxList_item">
            <ThemeCheckbox
              icon={checkboxUncheckedIcon}
              checkedIcon={checkboxCheckedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              onChange={() => {
                return null;
              }}
              handleOnChange={handleTeamChange}
            />
            {option.title}
          </li>
        )
      }
      renderTags={(values) => {
        return values.length === 0
          ? null
          : values.map((item, index) => (
              <div key={index}>
                {index !== 0 && `,`} {item?.title}
              </div>
            ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            selectedValues && selectedValues.length > 0 ? "" : placeholder
          }
          onKeyDown={(event: any) => {
            if (event.key === "Backspace") {
              event.stopPropagation();
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {selectedValues?.length ? (
                  <Close
                    onClick={handleClose}
                    style={{
                      position: "absolute",
                      right: "30px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ),
          }}
        />
      )}
    />
  );

  return (
    <div className="form_control">
      {label && (
        <InputLabel className="form_label" htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </InputLabel>
      )}
      {addField ? (
        <div className="add_select_wrap">
          {autoComplete()}
          <AppButton
            variant="text"
            className="addBtn"
            onClick={handleToggleAddPop}
          >
            {addIcon}
          </AppButton>
          {addFieldPop && (
            <ClickAwayListener onClickAway={handleToggleAddPop}>
              <div className="add_select_menu">
                <div className="input_wrap">
                  <input
                    type="text"
                    value={addInputValue}
                    placeholder={addFieldPlaceholder}
                    onChange={handleAddInput}
                  />
                </div>
                {addFieldError && (
                  <p style={{ margin: 0 }}>
                    <span className={styles.field_error}>
                      Please add option
                    </span>
                  </p>
                )}
                <div>
                  <AppButton
                    onClick={() => {
                      if (handleAddField) {
                        if (addInputValue.trim() !== "") {
                          handleAddField(addInputValue);
                          handleToggleAddPop();
                          setInputValue("");
                          setAddFieldError(false);
                        } else {
                          setAddFieldError(true);
                        }
                      }
                    }}
                  >
                    Add
                  </AppButton>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </div>
      ) : (
        autoComplete()
      )}
      <div className="error">
        {formik?.touched[name] && formik?.errors[name]}
      </div>
    </div>
  );
};
