import { useNavigate, useParams } from "react-router-dom";
import styles from "../scss/common/ProductCard.module.scss";
import useFetch from "../hook/CustomHook/usefetch";
import React, { useEffect, useState } from "react";
import { setProductData } from "../redux/productData";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BundleList from "../pageComponents/bundleDetail/bundleList";
import Layout from "../common/layouts/Layout";
import LoadingBars from "../common/sections/LoadingBars";
import AppContainer from "../common/theme/Container";
import BundleDetailCards from "../common/sections/bundleDetailCards";
import SubBundleDetailCards from "../common/sections/subBundleDetailCards";

const BundleDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const bundleId = params.id ? params.id : 2;
  const [productList, setProductList] = useState([]);
  const [bundleData, setBundleData] = useState<any>([]);
  const [subBundleData, setSubBundleData] = useState<any>([]);

  const [saveProductChange, setSaveProductChange] = useState(false);
  const [saveOptopnList, setSaveOptionList] = useState<any>([]);
  const [saveSubBundleOptionList, setSaveSubBundleOptionList] = useState<any>(
    []
  );
  const [completedSubBundle, setCompletedSubBundle] = useState<any>([]);
  const [openId, setOpenId] = useState<any>(0);
  const [checkAll, setCheckAll] = useState(false);
  const dispatch = useDispatch();
  const [
    loadOptionalProductsQuery,
    {
      response: responseBundleDetails,
      loading: loadingBundleDetails,
      error: errorBundleDetails,
    },
  ] = useFetch(`/bundle/info/${bundleId}`, {
    method: "get",
  });

  const saveProduct = (
    itemId: String,
    optionsId: any,
    value: any,
    price: any = 0,
    saveData: boolean = false
  ) => {
    const newSaveOptopnList = saveOptopnList;

    const getOptionIndex = saveOptopnList.findIndex(
      (option: any) =>
        option.optionsId === optionsId && option.productId === itemId
    );

    if (getOptionIndex >= 0) {
      newSaveOptopnList[getOptionIndex].value = value;
      if (optionsId === "quantity") {
        newSaveOptopnList[getOptionIndex].price = price;
      }

      if (saveData) {
        const newList = newSaveOptopnList.map((option: any) => {
          const newOptions = option;
          if (itemId === option.productId) newOptions.isChecked = true;

          return newOptions;
        });

        setSaveOptionList(newList);

        // newSaveOptopnList[getOptionIndex].isChecked = true;
      } else {
        setSaveOptionList(newSaveOptopnList);
      }
    }

    setSaveProductChange(!saveProductChange);
  };
  const removeSubBundleProduct = (
    bundleId: String = "",
    itemId: String,
    optionsId: any,
    value: any,
    price: any = 0,
    saveData: boolean = false
  ) => {
    const newSaveSubBundleOptionList = saveSubBundleOptionList;

    const getOptionIndex = saveSubBundleOptionList.findIndex(
      (option: any) =>
        option.optionsId === optionsId && option.productId === itemId
    );

    if (getOptionIndex >= 0) {
      newSaveSubBundleOptionList[getOptionIndex].value = "";
      if (optionsId === "quantity") {
        newSaveSubBundleOptionList[getOptionIndex].price = "";
      }

      if (saveData) {
        const newList = newSaveSubBundleOptionList.map((option: any) => {
          const newOptions = option;
          if (itemId === option.productId) newOptions.isChecked = false;

          return newOptions;
        });
        const completedSubBundleCopy = completedSubBundle.filter(
          (item: { product_id: string; bundleId: string }) =>
            item.product_id !== itemId && item.bundleId !== bundleId
        );

        setCompletedSubBundle(completedSubBundleCopy);

        setSaveSubBundleOptionList(newList);

        // newSaveSubBundleOptionList[getOptionIndex].isChecked = true;
      } else {
        setSaveSubBundleOptionList(newSaveSubBundleOptionList);
      }
    }

    setSaveProductChange(!saveProductChange);
  };

  const saveSubBundleProduct = (
    bundleId: String = "",
    itemId: String,
    optionsId: any,
    value: any,
    price: any = 0,
    saveData: boolean = false
  ) => {
    const newSaveSubBundleOptionList = saveSubBundleOptionList;

    const getOptionIndex = saveSubBundleOptionList.findIndex(
      (option: any) =>
        option.optionsId === optionsId && option.productId === itemId
    );

    if (getOptionIndex >= 0) {
      newSaveSubBundleOptionList[getOptionIndex].value = value;
      if (optionsId === "quantity") {
        newSaveSubBundleOptionList[getOptionIndex].price = price;
      }

      if (saveData) {
        const newList = newSaveSubBundleOptionList.map((option: any) => {
          const newOptions = option;
          if (itemId === option.productId) newOptions.isChecked = true;

          return newOptions;
        });
        const index = completedSubBundle.findIndex(
          (item: { product_id: string; bundleId: string }) =>
            item.product_id === itemId && item.bundleId === bundleId
        );

        if (index === -1) {
          setCompletedSubBundle(
            (prev: { product_id: string; bundleId: string }[]) => [
              ...prev,
              { product_id: itemId, bundleId: bundleId },
            ]
          );
        }
        setSaveSubBundleOptionList(newList);

        // newSaveSubBundleOptionList[getOptionIndex].isChecked = true;
      } else {
        setSaveSubBundleOptionList(newSaveSubBundleOptionList);
      }
    }

    setSaveProductChange(!saveProductChange);
  };

  const getSubBundleList = (subBundleList: any) => {
    const subBundles: any = [];
    subBundleList?.sub_bundle?.map((item: any) => {
      const updatedData = item.bundle_products.map((productDetail: any) => {
        const bundleProductOptions =
          productDetail?.store_product_detail?.store_options.map(
            (option: any) => {
              return {
                ...option,
                selectedData: 0,
              };
            }
          );
        return {
          ...productDetail,
          store_product_detail: {
            ...productDetail.store_product_detail,
            quantity: 0,
            store_options: bundleProductOptions,
          },
        };
      });
      subBundles.push({
        sub_bundle_id: item.id,
        sub_bundle_name: item.bundle_name,
        bundle_products: updatedData,
      });
    });
    setSubBundleData(subBundles);
  };

  useEffect(() => {
    if (
      responseBundleDetails &&
      responseBundleDetails.code === 200 &&
      responseBundleDetails.status === 1
    ) {
      if (
        responseBundleDetails.data &&
        responseBundleDetails.data.bundle_products
      ) {
        const productList: any = [];
        const bundleData = responseBundleDetails.data.bundle_products.map(
          (element: any) => {
            const bundleProductOptions =
              element.store_product_detail.store_options.map((option: any) => {
                return {
                  ...option,
                  selectedData: 0,
                };
              });

            return {
              ...element,
              store_product_detail: {
                ...element.store_product_detail,
                quantity: 1,
                store_options: bundleProductOptions,
              },
            };
          }
        );
        getSubBundleList(responseBundleDetails.data);
        setBundleData({
          ...responseBundleDetails.data,
          bundle_products: bundleData,
        });
        responseBundleDetails.data.bundle_products.map((product: any) => {
          productList.push(product);
        });
        dispatch(setProductData(responseBundleDetails.data));
        setProductList(productList);
      }
    }
    if (errorBundleDetails && errorBundleDetails?.code?.toString() === "404") {
      toast.error(errorBundleDetails.message);
      navigate("/");
    }
  }, [responseBundleDetails, errorBundleDetails]);

  useEffect(() => {
    const saveList: any = [];
    productList &&
      productList.map((item: any, i) => {
        saveList.push({
          productId: item.id,
          options: {},
          optionsId: "quantity",
          value: "1",
          isChecked: false,
        });
        item.store_product_detail.store_options.map((options: any) => {
          saveList.push({
            productId: item.id,
            options,
            optionsId: options.id,
            value: "",
            isChecked: false,
          });
        });
      });
    setSaveOptionList(saveList);
  }, [productList]);

  useEffect(() => {
    const saveList: any = [];
    subBundleData &&
      subBundleData.map((bundle: any, i: number) => {
        bundle.bundle_products.map((item: any, j: number) => {
          saveList.push({
            productId: item.id,
            options: {},
            optionsId: "quantity",
            value: "0",
            isChecked: false,
          });
          item.store_product_detail.store_options.map((options: any) => {
            saveList.push({
              productId: item.id,
              options,
              optionsId: options.id,
              value: "",
              isChecked: false,
            });
          });
        });
      });
    setSaveSubBundleOptionList(saveList);
  }, [subBundleData]);

  useEffect(() => {
    loadOptionalProductsQuery();
  }, []);

  const selectedSubBundleProduct = saveSubBundleOptionList.filter(
    (item: any) => {
      const index = completedSubBundle.findIndex(
        (innerItem: any) => innerItem.product_id === item.productId
      );
      return index !== -1 ? true : false;
    }
  );
  const isHide = subBundleData.length !== completedSubBundle.length;

  return (
    <Layout>
      {loadingBundleDetails ? (
        <LoadingBars />
      ) : (
        <>
          <AppContainer>
            <div className={styles.custom_bundleDetailAccordion}>
              <div className={styles.bundleDetail_row}>
                <h2 className={styles.bundleName}>{bundleData?.bundle_name}</h2>
                {productList &&
                  productList.map((item: any, i) => (
                    <div className={styles.bundleDetail_lg} key={i}>
                      <BundleDetailCards
                        item={item.store_product_detail}
                        itemId={item.id}
                        i={i}
                        length={productList.length}
                        setCheckAll={setCheckAll}
                        openId={openId}
                        setOpenId={setOpenId}
                        saveProduct={saveProduct}
                        saveOptopnList={saveOptopnList}
                        saveProductChange={saveProductChange}
                        setSaveProductChange={setSaveProductChange}
                      />
                    </div>
                  ))}
              </div>
              <div className={styles.sub_bundle_wrapper}>
                {subBundleData?.map((bundleDetail: any, i: number) => {
                  return (
                    <div className={styles.bundleDetail_row}>
                      <h4 className={styles.bundleName}>
                        Sub Bundle{" "}
                        <span className={styles.info}>
                          (You can only select one product from the list of
                          sub-bundle.)
                        </span>
                      </h4>
                      {bundleDetail?.bundle_products?.map(
                        (productDetail: any, index: number) => (
                          <div
                            className={styles.bundleDetail_lg}
                            key={`${i + 1}${index}${index}`}
                          >
                            <SubBundleDetailCards
                              item={productDetail.store_product_detail}
                              itemId={productDetail.id}
                              bundleId={bundleDetail.sub_bundle_id}
                              i={Number(`${i + 1}${index}${index}`)}
                              length={productList.length}
                              setCheckAll={setCheckAll}
                              openId={openId}
                              setOpenId={setOpenId}
                              saveProduct={saveSubBundleProduct}
                              removeSubBundleProduct={removeSubBundleProduct}
                              saveSubBundleOptionList={saveSubBundleOptionList}
                              saveProductChange={saveProductChange}
                              setSaveProductChange={setSaveProductChange}
                              selectedProduct={completedSubBundle}
                            />
                          </div>
                        )
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </AppContainer>
          <BundleList
            bundleDataList={bundleData}
            saveSubBundleOptionList={selectedSubBundleProduct}
            productList={productList}
            saveProduct={saveProduct}
            saveOptopnList={saveOptopnList}
            saveProductChange={saveProductChange}
            checkAll={checkAll}
            isHide={isHide}
          />
        </>
      )}
    </Layout>
  );
};

export default BundleDetail;
