import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import { IMAGE_BASE_URL } from "../../constants/constants";
import styles from "../../scss/common/ProductCard.module.scss";
import { InputField } from "../theme/Input";
import { SelectField } from "../theme/select";
import { useParams } from "react-router-dom";
import { Decrypt, imageOnError } from "../utils/Functions";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import AppButton from "../theme/Button";
import AppContainer from "../theme/Container";

interface IProps {
  item: any;
  itemId: string;
  bundleId: string;
  i: number;
  length: number;
  setCheckAll: (value: boolean) => void;
  openId: any;
  setOpenId: (value: any) => void;
  saveProduct: (
    bundleId: string,
    productId: string,
    options: string,
    value: any,
    price?: number,
    save?: boolean
  ) => void;
  removeSubBundleProduct: (
    bundleId: string,
    productId: string,
    options: string,
    value: any,
    price?: number,
    save?: boolean
  ) => void;
  saveSubBundleOptionList: any;
  saveProductChange: boolean;
  setSaveProductChange: (value: boolean) => void;
  selectedProduct: any;
}
const SubBundleDetailCards = ({
  item,
  itemId,
  bundleId,
  i,
  length,
  setCheckAll,
  openId,
  setOpenId,
  saveProduct,
  removeSubBundleProduct,
  saveSubBundleOptionList,
  saveProductChange,
  setSaveProductChange,
  selectedProduct,
}: IProps) => {
  const params = useParams();
  // const [age, setAge] = React.useState("");
  const [check, setCheck] = useState(false);
  const [error, setError] = useState("");
  const [expanded, setExpanded] = useState(false);
  // const [firstOpen, setFirstOpen] = useState(false);
  const [checkSave, setCheckSave] = useState(false);
  const [qty, setQty] = useState("0");
  const [selectId, setSelectedId] = useState<any>(null);
  const [productCartQty, setProductCartQty] = useState(0);

  // const handleChange = (event: SelectChangeEvent) => {
  //   setAge(event.target.value as string);
  // };
  const cartDataInfoD = localStorage?.getItem("cart");
  const cartDataInfo = Decrypt(cartDataInfoD);
  const cartAllData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

  useEffect(() => {
    if (item.minimum_quantity && item.minimum_quantity !== null) {
      setQty(item.minimum_quantity);
      saveProduct(
        bundleId,
        itemId,
        "quantity",
        item.minimum_quantity,
        item.club_price
      );
    } else {
      setQty("0");
      saveProduct(bundleId, itemId, "quantity", 0, item.club_price);
    }
  }, [item]);

  useEffect(() => {
    if (i === openId) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [i, openId]);

  const handleClearSelection = () => {
    setCheck(false);
    setQty("0");
    removeSubBundleProduct(
      bundleId,
      itemId,
      "quantity",
      qty,
      item.club_price,
      true
    );
  };

  const save = () => {
    setCheckSave(true);
    if (Number(qty) <= 0) {
      setError("Minimum Quantity allowed is 1");
    }
    const data = saveSubBundleOptionList.filter((product: any) => {
      return product.value === "" && product.productId === itemId;
    });

    if (data.length > 0) {
      setCheck(false);
    } else {
      saveProduct(bundleId, itemId, "quantity", qty, item.club_price, true);
      if (Number(qty) > 0) {
        setCheck(true);
        // setFirstOpen(false);
        setOpenId(i + 1);

        setSaveProductChange(!saveProductChange);
      } else {
        setError("Minimum Quantity allowed is 1");
      }
    }
    if (i === length - 1) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const changeQty = (newQty: any) => {
    setQty(parseInt(newQty).toString());
    const mimQty =
      item && item.minimum_quantity && item.minimum_quantity !== null
        ? item.minimum_quantity
        : 0;
    if (newQty === "") {
      setError("Minimum Quantity allowed is " + mimQty);
    } else if ((newQty || newQty === 0) && newQty < parseInt(mimQty)) {
      setError("Minimum Quantity allowed is " + mimQty);
    } else if (
      item &&
      item.maximum_quantity &&
      item.maximum_quantity !== null &&
      (newQty || newQty === 0) &&
      newQty > Number(item.maximum_quantity) - Number(productCartQty)
    ) {
      setError("Maximum Quantity allowed is " + item.maximum_quantity);
    } else {
      setError("");

      saveProduct(
        bundleId,
        itemId,
        "quantity",
        parseInt(newQty),
        item.club_price
      );
    }
  };

  useEffect(() => {
    const cartItems = cartAllData.find(
      (item: any) => item.id == params.id && !item.isSingleProduct
    );

    if (cartItems) {
      const bundleProducts = cartItems.bundle_products;
      const cartBundleProducts = bundleProducts.filter(
        (item: any) => item.id == itemId
      );
      let totalQty = 0;
      cartBundleProducts.map((item: any) => {
        totalQty += Number(item.store_product_detail.quantity);
      });

      setProductCartQty(totalQty);
    }
  }, [cartAllData]);
  const isSaveButtonDisabled = selectedProduct.find(
    (item: { product_id: string; bundleId: string }) =>
      item.bundleId === bundleId
  );
  let isDisabled = false;

  if (isSaveButtonDisabled?.bundleId === bundleId) {
    if (isSaveButtonDisabled?.product_id === itemId) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }
  } else {
    isDisabled = false;
  }

  return (
    <AppContainer>
      <div className={styles.bundleDetail}>
        <div className={styles.bundleDetail}>
          <Accordion
            onChange={() => {
              if (!expanded && isDisabled) {
                toast.warning(
                  "You have already selected a product from this sub-bundle."
                );
              }
              setExpanded(!expanded);
              setOpenId(i);
            }}
            expanded={expanded}
            className={`containerAccordion ${styles.acoContainer}`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={styles.bundleDetail_heading}>
                {check && !expanded ? (
                  <label className={styles.bundleDetail_checkbox}>
                    <input type="checkbox" checked />
                    <span className={styles.bundleDetail_checkmark}></span>
                  </label>
                ) : null}
                {!expanded && (
                  <div className={styles.imgWrap}>
                    <img
                      loading="lazy"
                      onError={imageOnError}
                      src={
                        item.store_product_images[0]
                          ? IMAGE_BASE_URL + item.store_product_images[0].image
                          : "/images/prodPlaceholder.png"
                      }
                      alt=""
                    />
                  </div>
                )}

                <Typography className={styles.accordionTitle}>
                  {item.product_name}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.detailWrap}>
                <div className={styles.imgWrap}>
                  <img
                    loading="lazy"
                    onError={imageOnError}
                    src={
                      item.store_product_images[0]
                        ? IMAGE_BASE_URL + item.store_product_images[0].image
                        : "/images/prodPlaceholder.png"
                    }
                    alt=""
                  />
                </div>
                <div className={styles.contentWrap}>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                      <p className={styles.cartQty}>
                        {productCartQty !== 0
                          ? `Currently in your cart - ${productCartQty}`
                          : ""}
                      </p>
                    </Grid> */}

                    <Grid item xs={6}>
                      <div className={styles.bundle_custom_filed}>
                        <Box component="form" noValidate autoComplete="off">
                          <InputField
                            disabled={isDisabled}
                            name="quantity"
                            valueData={qty}
                            isNotFormik={true}
                            type="number"
                            label="Quantity"
                            placeholder="Enter quantity"
                            onChangeWork={(items) =>
                              changeQty(items?.target?.value)
                            }
                            inputProps={{
                              max:
                                Number(item.maximum_quantity) -
                                Number(productCartQty),
                              min: parseInt(item.minimum_quantity),
                            }}
                          />
                          <div className={styles.loseHigh}>
                            <button
                              disabled={isDisabled}
                              onClick={(e) => {
                                e.preventDefault();
                                if (Number(qty) > 0) {
                                  changeQty(Number(qty) - 1);
                                }
                              }}
                              className={styles.lose}
                            >
                              -
                            </button>
                            <button
                              disabled={isDisabled}
                              onClick={(e) => {
                                e.preventDefault();
                                changeQty(Number(qty) + 1);
                              }}
                              className={styles.High}
                            >
                              +
                            </button>
                          </div>
                        </Box>
                        <span className={styles.error}>{error}</span>
                        <p className={styles.cartQty}>
                          {item &&
                          item.minimum_quantity &&
                          item.minimum_quantity !== null
                            ? `${item.minimum_quantity} minimum `
                            : ""}{" "}
                          {item &&
                          item.maximum_quantity &&
                          item.maximum_quantity !== null
                            ? ` - ${item.maximum_quantity} maximum`
                            : ""}
                        </p>
                      </div>
                    </Grid>
                    {item.store_options
                      ? item.store_options.map(
                          (optionsData: any, index: number) => {
                            const display_name =
                              item.store_product_options[index]?.display_name ??
                              optionsData.display_name;
                            if (optionsData.option_type === 1) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );

                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }

                                return (
                                  <Grid item xs={6}>
                                    <SelectField
                                      disabled={isDisabled}
                                      label={display_name}
                                      // label="Size"
                                      value={selectedValue}
                                      name=""
                                      placeholder="Select Size"
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select size
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      disabled={isDisabled}
                                      isNotFormik={true}
                                      name=""
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(item) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          item.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      name=""
                                      disabled={isDisabled}
                                      isNotFormik={true}
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );

                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }

                                return (
                                  <Grid item xs={6}>
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {display_name}
                                        {/* Size */}
                                      </FormLabel>
                                      <RadioGroup
                                        onChange={(items) => {
                                          saveProduct(
                                            bundleId,
                                            itemId,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                          disabled={isDisabled}
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                          disabled={isDisabled}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select{" "}
                                        <span
                                          style={{
                                            textTransform: "lowercase",
                                            color: "red",
                                          }}
                                        >
                                          size
                                        </span>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              }
                            } else if (optionsData.option_type === 2) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) => {
                                    return (
                                      product.value === selectId &&
                                      product.optionsId === optionsData.id
                                    );
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <SelectField
                                      label={display_name}
                                      disabled={isDisabled}
                                      // label="Color"
                                      name=""
                                      value={selectedValue}
                                      placeholder={`Select ${
                                        optionsData.option_type === 2
                                          ? "Color"
                                          : optionsData.display_name
                                      }`}
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.value
                                        );
                                        setSelectedId(items?.value);
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select{" "}
                                        {optionsData.option_type === 2
                                          ? "color"
                                          : optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      name=""
                                      disabled={isDisabled}
                                      isNotFormik={true}
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      name=""
                                      disabled={isDisabled}
                                      isNotFormik={true}
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) => {
                                    return product.optionsId === optionsData.id;
                                  }
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {display_name}
                                        {/* Color */}
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(items) => {
                                          saveProduct(
                                            bundleId,
                                            itemId,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                          disabled={isDisabled}
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                          disabled={isDisabled}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {check && selectedValue === "" ? (
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                          marginTop: "5px",
                                          display: "block",
                                          left: "1px",
                                        }}
                                      >
                                        Please select {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              }
                            } else if (optionsData.option_type === 3) {
                              if (optionsData.input_type === 1) {
                                const selectOption: {
                                  title: any;
                                  value: any;
                                }[] = [];
                                optionsData.store_option_items.map(
                                  (option: any) => {
                                    selectOption.push({
                                      title: option.option_name,
                                      value: option.id,
                                    });
                                  }
                                );

                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <SelectField
                                      label={optionsData.display_name}
                                      name=""
                                      disabled={isDisabled}
                                      value={selectedValue}
                                      placeholder={`Select ${optionsData.display_name}`}
                                      options={selectOption}
                                      selectedValue={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 2) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id &&
                                    product.productId === itemId
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      name=""
                                      disabled={isDisabled}
                                      isNotFormik={true}
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 3) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }
                                return (
                                  <Grid item xs={6}>
                                    <InputField
                                      isNotFormik={true}
                                      name=""
                                      disabled={isDisabled}
                                      label={optionsData.display_name}
                                      placeholder={`Enter ${optionsData.display_name}`}
                                      valueData={selectedValue}
                                      onChangeWork={(items) => {
                                        saveProduct(
                                          bundleId,
                                          itemId,
                                          optionsData.id,
                                          items.target.value
                                        );
                                      }}
                                    />
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please enter {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              } else if (optionsData.input_type === 4) {
                                const data = saveSubBundleOptionList.filter(
                                  (product: any) =>
                                    product.optionsId === optionsData.id
                                );
                                let selectedValue = "";
                                if (data.length > 0) {
                                  selectedValue = data[0].value;
                                }

                                return (
                                  <Grid item xs={6}>
                                    <FormControl>
                                      <FormLabel
                                        className="form_label"
                                        id="demo-row-radio-buttons-group-label"
                                      >
                                        {optionsData.display_name}
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={(items) => {
                                          saveProduct(
                                            bundleId,
                                            itemId,
                                            optionsData.id,
                                            items.target.value
                                          );
                                        }}
                                      >
                                        <FormControlLabel
                                          value="Yes"
                                          control={<Radio />}
                                          label="Yes"
                                          disabled={isDisabled}
                                        />
                                        <FormControlLabel
                                          value="No"
                                          control={<Radio />}
                                          label="No"
                                          disabled={isDisabled}
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                    {checkSave && selectedValue === "" ? (
                                      <span className={styles.error}>
                                        Please select {optionsData.display_name}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                );
                              }
                            }
                          }
                        )
                      : "1"}
                  </Grid>
                  {item?.description.replace(/(<([^>]+)>)/gi, "").trim() !==
                  "" ? (
                    <div className={styles.bundleDetail_cardlist}>
                      <h5>Description</h5>
                      <div
                        className={styles.htmlWrap}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </div>
                  ) : (
                    <br />
                  )}
                  <AppButton
                    onClick={save}
                    style={{ cursor: "pointer" }}
                    disabled={error !== "" || isDisabled}
                    variant="contained"
                    color={
                      saveSubBundleOptionList.filter((product: any) => {
                        return (
                          product.value === "" && product.productId === itemId
                        );
                      }).length > 0 || Number(qty) <= 0
                        ? "primary"
                        : "success"
                    }
                  >
                    Save & Continue
                  </AppButton>
                  {check ? (
                    <AppButton
                      onClick={handleClearSelection}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      variant="outlined"
                      color={"primary"}
                    >
                      Clear Selection
                    </AppButton>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </AppContainer>
  );
};

export default SubBundleDetailCards;
