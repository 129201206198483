import moment from "moment";
import CommingSoon from "../assets/images/CommingSoon.svg";
import styles from "../scss/common/common.module.scss";

const ComingSoon = ({ openDate }: any) => {
  return (
    <div className={styles.storeNotFound} style={{ height: "100vh" }}>
      <div>
        <img loading="lazy" src={CommingSoon} alt="" />
        <h4>Coming soon</h4>
        <p style={{ paddingTop: "0px" }}>
          Your store will open on -{" "}
          <b>{moment(new Date(openDate * 1000)).format("l")}</b>
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
