import styles from "../../scss/common/common.module.scss";

interface IProps {
  image: string;
}
const Banner = ({ image }: IProps) => {
  return (
    <section
      className={styles.banner}
      // style={{ backgroundImage: `url(${image})` }}
    >
      <img
        loading="lazy"
        className={styles.watermark}
        src="/images/renHubWatermark.png"
        alt=""
      />

      <img
        loading="lazy"
        className={styles.banner_image}
        src={image}
        alt="banner"
      />
    </section>
  );
};

export default Banner;
