import React from "react";
import { RotatingLines } from "react-loader-spinner";

const LoadingBars = () => {
  return (
    <span className="loadingBars">
      <RotatingLines
        strokeColor="currentColor"
        strokeWidth="5"
        animationDuration="0.75"
        width="20"
      />
    </span>
  );
};

export default LoadingBars;
