import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { IMAGE_BASE_URL } from "../../constants/constants";
import styles from "../../scss/pageComponent/checkoutCart.module.scss";
// import Image from "../../assets/images/DefaultProduct.png";
// import styles from "../../scss/common/common.module.scss";

import { useSelector } from "react-redux";
import { imageOnError } from "../../common/utils/Functions";
import Image from "../../assets/images/DefaultProduct.png";
import BundleItemModal from "../../common/sections/bundleItemModal";

export default function ComplexGrid({
  cartItem,
  reLoadCart,
  setReLoadCart,
}: {
  cartItem: any;
  reLoadCart: boolean;
  setReLoadCart: (value: boolean) => void;
}) {
  const storeData = useSelector((state: any) => state.storeDataSlice.data);
  const [cartImages, setCartImages] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalProductQty, setTotalProductQty] = useState(0);
  const hasMultiImages = cartImages.length > 1;
  useEffect(() => {
    const images: any = [];

    if (cartItem) {
      let bundlePrice = 0;
      let bundleItem = 0;
      if (!cartItem.isSingleProduct) {
        cartItem.bundle_products.map((product: any) => {
          bundlePrice +=
            product.store_product_detail.club_price *
            product.store_product_detail.quantity;
          if (product.store_product_detail.store_product_images.length > 0) {
            images.push({
              img: product.store_product_detail.store_product_images[0].image,
            });
          } else {
            images.push({ img: Image, noImg: true });
          }
          bundleItem += product.store_product_detail.quantity;
        });
      } else {
        if (cartItem.store_product_images.length > 0) {
          images.push({ img: cartItem.store_product_images[0].image });
        } else {
          images.push({ img: Image, noImg: true });
        }
      }
      setTotalProductQty(bundleItem);
      setTotalProductPrice(bundlePrice);
    }
    setCartImages(images);
  }, [cartItem, reLoadCart]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setReLoadCart(!reLoadCart);
    setOpen(false);
  };

  // const [ImageData, setImageData] = useState(0);

  return (
    <>
      <Grid container className={styles.fullCard}>
        <Grid item>
          <div className={styles.shoppingCart_img}>
            {cartImages.length ? (
              cartImages.map((img: any, index) =>
                index < 4 ? (
                  <div
                    className={`${styles.img_wrap} ${
                      hasMultiImages ? styles.multiImage : ""
                    }`}
                  >
                    {index === 3 && cartImages.length > 4 ? (
                      <div className={styles.layer}>
                        +{cartImages.length - 4}
                      </div>
                    ) : (
                      <></>
                    )}

                    <img
                      loading="lazy"
                      onError={(event) => imageOnError(event)}
                      src={img.noImg ? img.img : IMAGE_BASE_URL + img.img}
                      alt=""
                    />
                  </div>
                ) : (
                  <></>
                )
              )
            ) : (
              <>
                <div className={styles.img_wrap}>
                  <img
                    loading="lazy"
                    onError={(event) => imageOnError(event)}
                    src={Image}
                    alt=""
                  />
                </div>
              </>
            )}
          </div>
          {/* <div
            className={styles.summaryCard}
            // style={{ border: "2px solid red" }}
          >
            {cartImages.length > 0 ? (
              cartImages.map((val: any) => {
                return (
                  <>
                    <img loading="lazy"
                      className={
                        !cartItem.isSingleProduct
                          ? styles.imgResp
                          : styles.imgRespSingle
                      }
                      alt="complex"
                      src={val.noImg ? val.img : IMAGE_BASE_URL + val.img}
                    />
                  </>
                );
              })
            ) : (
              <img loading="lazy" src={Image} alt="" />
            )}
          </div> */}
        </Grid>

        {/* <div className={stylesImages.shoppingCart_img}>
            {cartImages.length ? (
              cartImages.map((img:any, index) =>
                index < 4 ? (
                  <div
                    className={`${stylesImages.img_wrap} ${
                      hasMultiImages ? stylesImages.multiImage : ""
                    }`}
                  >
                    {index === 3 && cartImages.length > 4 ? (
                      <div className={stylesImages.layer}>
                        +{cartImages.length - 4}
                      </div>
                    ) : (
                      <></>
                    )}

                    <img loading="lazy"
                      onError={(event) => imageOnError(event)}
                      src={img.noImg ? img.img :IMAGE_BASE_URL + img.img}
                      alt=""
                    />
                  </div>
                ) : (
                  <></>
                )
              )
            ) : (
              <>
                <div className={stylesImages.img_wrap}>
                  <img loading="lazy"
                    onError={(event) => imageOnError(event)}
                    src={Image}
                    alt=""
                  />
                </div>
              </>
            )}
          </div>       */}

        <Grid className={styles.heightCard} item xs={12} sm container>
          <Grid
            item
            xs
            container
            direction="column"
            className={styles.heightCardInner1}
          >
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {!cartItem.isSingleProduct
                  ? cartItem?.bundle_name
                  : cartItem?.product_name}{" "}
              </Typography>
              {!cartItem.isSingleProduct ? (
                <Typography
                  onClick={handleOpen}
                  // onClick={() => navigate(`/bundle-detail/${cartItem?.id}`)}
                  variant="body2"
                  gutterBottom
                  style={{ cursor: "pointer" }}
                >
                  <u> View Bundle</u>
                </Typography>
              ) : (
                <Typography
                  onClick={handleOpen}
                  // onClick={() => navigate(`/bundle-detail/${cartItem?.id}`)}
                  variant="body2"
                  gutterBottom
                  style={{ cursor: "pointer" }}
                >
                  <u> View Details</u>
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography
                sx={{ cursor: "pointer" }}
                variant="body2"
              ></Typography>
              <div className={styles.shoppingChat_items}>
                <button className={styles.qtyDiv}>
                  {!cartItem.isSingleProduct
                    ? `Qty : 1 bundle (${totalProductQty} items)`
                    : `Qty : ${cartItem.quantity} items`}
                </button>
              </div>
              {/* <div
              className={styles.qtyDiv}
            
                
              >
                {!cartItem.isSingleProduct
                  ? `Qty:1 (${totalProductQty} items)`
                  : `Qty:${cartItem?.quantity}`}
 
              </div> */}
            </Grid>
          </Grid>
          <Grid item className={styles.heightCardInner}>
            <Typography variant="subtitle1" component="div">
              <b>
                {" "}
                {!cartItem.isSingleProduct
                  ? `${
                      cartItem.club_price === null ||
                      storeData?.payment_handle_type !== 1
                        ? `$${(0).toFixed(2)}`
                        : `$${parseFloat(totalProductPrice.toString()).toFixed(
                            2
                          )}`
                    }`
                  : `$${parseFloat(
                      (cartItem?.club_price * cartItem?.quantity).toString()
                    ).toFixed(2)}`}
              </b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <BundleItemModal
        cart={cartItem}
        title=""
        subtitle=""
        size=""
        price=""
        handleOpen={handleOpen}
        handleClose={handleClose}
        open={open}
        checkoutPage={true}
      />
    </>
    // </Paper>
  );
}
