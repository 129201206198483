import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../scss/common/common.module.scss";
import AppButton from "../common/theme/Button";

const StoreExpired = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  return (
    <div className={styles.storeNotFound}>
      <div className={styles.storeExpire}>
        <img loading="lazy" src="/images/storeExpired.svg" alt="" />
        <h4>The store has expired!</h4>
        <p>
          This store is past its deadline and is no longer <br /> accepting
          orders.
        </p>
        {pathName !== "/" ? (
          <AppButton
            className={styles.storeNotFoundBtn}
            onClick={() => navigate("/")}
          >
            Back To Home
          </AppButton>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StoreExpired;
