import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import BannerBundleHome from "../../assets/images/bundleDetailHomeicon.svg";
import BannerBundleHome from "../../assets/images/bundleDetailHomeicon.svg";
import styles from "../../scss/common/common.module.scss";
import React from "react";
import AppContainer from "../../common/theme/Container";
interface IProps {
  image: string;
  pageTitle?: string;
}
const BundleDetailBanner = ({ image, pageTitle }: IProps) => {
  const cartCount = useSelector((state: any) => state.cartDataSlice.cartCount);

  const productDataList = useSelector(
    (state: any) => state.ProductDataSlice.productDataList
  );

  return (
    <section
      className={styles.banner_bundle}
      style={{ backgroundImage: `url(${image})` }}
    >
      <AppContainer>
        <div className={styles.headingContent}>
          <Link to="/">
            <img
              loading="lazy"
              className={styles.bannerImg}
              src={BannerBundleHome}
              alt="img"
            />
          </Link>
          <span className={styles.bannerSubText}>
            {pageTitle === "cart"
              ? cartCount > 0
                ? `/ Shopping Cart`
                : `/ Empty Cart`
              : pageTitle === "thankyou"
                ? "/ Thank You"
                : productDataList
                  ? pageTitle === "checkout"
                    ? "/ Checkout"
                    : `/ ${
                        productDataList.bundle_name
                          ? productDataList.bundle_name ?? ""
                          : productDataList.product_name ?? ""
                      }`
                  : "/ Travel Premier Player Package"}
          </span>
        </div>
      </AppContainer>
      {/* <img loading="lazy" src={image} alt="banner" /> */}
    </section>
  );
};

export default BundleDetailBanner;
