/* eslint-disable no-nested-ternary */
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { makeStyles } from "@mui/styles";

interface IInputProps {
  formik?: any;
  name: string;
  required?: boolean;
  prefix?: string;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isUrl?: boolean;
  isNotFormik?: boolean;
  onChangeWork?: (e: any) => void;
  textFieldProps?: TextFieldProps;
  inputProps?: {
    maxLength?: number;
    minLength?: number;
    max?: number;
    min?: number;
  };
  valueData?: string;
  sign?: string;
}
const useStyles = makeStyles({
  formControl: {
    "& input::placeholder": {
      color: "#898888",
      fontWeight: 400,
    },
  },
});
export const InputField = ({
  isUrl,
  required,
  formik,
  type = "text",
  name,
  prefix,
  placeholder,
  label,
  inputProps,
  disabled,
  isNotFormik = false,
  onChangeWork,
  textFieldProps,
  valueData,
  sign,
}: IInputProps) => {
  const [isType, setIsType] = useState(type);
  const handleEyeToggle = () => {
    setIsType(isType === "text" ? "password" : "text");
  };

  const handleFormikOnChange = (e: any) => {
    if (!isNotFormik) {
      const regex = /^\d*[.]?\d*$/;
      if (type === "number") {
        if (regex.test(e.target.value)) {
          formik.setFieldValue(name, e.target.value);
        }
      } else {
        formik.handleChange(e);
      }
    }
  };

  const textField = () => {
    const textFieldPropNew: any = {
      ...textFieldProps,
    };
    if (sign && sign !== "") {
      textFieldPropNew.InputProps = {
        startAdornment: (
          <InputAdornment position="start">{sign}</InputAdornment>
        ),
      };
    }
    return (
      <div className="form_input_wrap">
        {prefix && <span className="input_prefix">{prefix}</span>}
        <TextField
          className={`form_input   ${prefix ? "hasPrefix" : ""} ${
            type === "password" ? "type_password" : ""
          }`}
          type={isType}
          placeholder={placeholder}
          name={name}
          // value={isNotFormik ? valueData : formik?.values[name]}
          value={
            valueData
              ? valueData
              : isNotFormik
              ? valueData
              : formik?.values[name]
          }
          onChange={isNotFormik ? onChangeWork : handleFormikOnChange}
          // onChange={onChangeWork}
          id={name}
          error={formik?.touched[name] && formik?.errors[name]}
          disabled={disabled}
          // required={required}
          {...textFieldPropNew}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#8D8A8C",
            },
            "& .MuiInputBase-input":
              sign === "$"
                ? {
                    paddingLeft: "0 !important",
                  }
                : {},
            "& .MuiInputAdornment-root":
              sign === "$"
                ? {
                    marginRight: "1px !important",
                  }
                : {},
          }}
          inputProps={inputProps}
        />
        {type === "password" && (
          <span className="form_input_icon" onClick={handleEyeToggle}>
            {isType === "password" ? <VisibilityOff /> : <Visibility />}
          </span>
        )}
        <div className="error">
          {formik?.touched[name] && formik.errors[name]}
        </div>
      </div>
    );
  };
  return (
    <div className="form_control">
      {label && (
        <InputLabel
          className="form_label"
          style={{ textTransform: "inherit" }}
          htmlFor={name}
        >
          {label}
          {required && <sup>*</sup>}
        </InputLabel>
      )}
      {isUrl ? (
        <div className="url_field_wrap">
          <span>https://</span>
          <div className="url_input">{textField()}</div>
          <span>.renathletics.com</span>
        </div>
      ) : (
        textField()
      )}
    </div>
  );
};
