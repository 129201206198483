import moment from "moment";
import { useEffect, useState } from "react";
import { IStoreDesignData } from "../../interfaces/StoreInterface";
import styles from "../../scss/common/common.module.scss";
interface ITimeSecData {
  timeSecData: IStoreDesignData;
}
const TimerSection = ({ timeSecData }: ITimeSecData) => {
  const [days, setDays] = useState(0);
  const [time, setTime] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [expired, setExpired] = useState(false);
  const deadline = new Date(timeSecData?.close_date_time * 1000);
  const startDate = new Date();
  const getTime = () => {
    const time = +deadline - +startDate;
    const currentDate = moment();
    const closeDateTm = moment.unix(timeSecData?.close_date_time);
    if (currentDate.diff(closeDateTm, "milliseconds") > 0) {
      setExpired(true);
    } else {
      setTime(time);
      const remainingDay = Math.floor(time / (1000 * 60 * 60 * 24));
      const remainingHour = Math.floor((time / (1000 * 60 * 60)) % 24);
      const remainingMinute = Math.floor((time / 1000 / 60) % 60);
      setDays(remainingDay);
      setHours(remainingHour);
      setMinutes(remainingMinute);
    }
  };
  useEffect(() => {
    const i = setInterval(getTime, 1000);
    return () => clearInterval(i);
  }, [time]);
  return (
    <section className={styles.timer_section}>
      <div className={styles.content_box}>
        <div className="heading left">
          <h2>{timeSecData?.store_name}</h2>
          <h4>
            {`${
              timeSecData?.store_organization?.organization_name ?? ""
            } | ${moment(new Date(timeSecData?.created_at)).format(
              "MMM YYYY"
            )} `}
          </h4>
        </div>
        {timeSecData?.close_date_time !== null ? (
          <div className={styles.timer_box}>
            <div className={styles.dateBtn}>
              Store deadline -{" "}
              {moment(new Date(timeSecData?.close_date_time * 1000)).format(
                "l"
              )}
            </div>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Order Before Your Store Closes
            </p>
            {expired ? (
              <div
                className={styles.timeBox_wrapper}
                style={{ fontWeight: "500" }}
              >
                Expired
              </div>
            ) : (
              <div className={styles.timeBox_wrapper}>
                <div className={styles.timeBox}>
                  <h2>{days}</h2>
                  <h5>{days > 1 || days === 0 ? "days" : "day"}</h5>
                </div>
                <div className={styles.timeBox}>
                  <h2>{hours}</h2>
                  <h5>{hours > 1 || hours === 0 ? "hours" : "hour"}</h5>
                </div>
                <div className={styles.timeBox}>
                  <h2>{minutes}</h2>
                  <h5>{minutes > 1 || minutes === 0 ? "mins" : "min"}</h5>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default TimerSection;
