import saveAs from "file-saver";
import CryptoJS from "crypto-js";
const secretKey = "TshFrqCrxs2MBTf";

export const strip_html_tags = (str: string) => {
  if (str === null || str === "") return "";
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
};

export const clearUserLocalData = () => {
  localStorage.removeItem("RENath_userInfo");
  localStorage.removeItem("RENath_token");
  sessionStorage.removeItem("rm_accountTab");
};
export const imageOnError = (event: any) => {
  event.currentTarget.onerror = null; // prevents looping
  event.currentTarget.src = "/images/prodPlaceholder.png";
};

/** download pdf functions */
export const downloadPDF = async (pdfUrl: string, customFileName: string) => {
  return fetch(pdfUrl, {
    method: "GET",
    headers: {
      "Cache-Control": "no-store",
    },
  })
    .then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      saveAs(blob, customFileName);
      return true;
    })
    .catch((error) => {
      console.error("Error downloading the PDF file:", error);
      return true;
    });
};

export const removeInitialSpace = (value: string) => value.replace(/^\s+/g, "");

// Get two numbers after decimals
export const trimNumber = (number: number) =>
  number?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }) ?? "0.00";

export const isEncrypted = (data: any) => {
  return data.startsWith("ENC:");
};

/** Encrypt Decrypt Data */
export function Encrypt(values: any) {
  const encrypted = CryptoJS.AES.encrypt(values, secretKey).toString();
  return `ENC:${encrypted}`;
}

export function Decrypt(encryptedData: string | null) {
  if (encryptedData) {
    if (encryptedData.startsWith("ENC:")) {
      const encryptedText = encryptedData.slice(4); // Remove "ENC:" prefix
      const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText || ""; // Return original text or an error message
    } else {
      return encryptedData || "";
    }
  } else {
    return "";
  }
}
