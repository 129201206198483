import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DIRECT_ACCESS_URL, IMAGE_BASE_URL } from "../../constants/constants";
import useFetch from "../../hook/CustomHook/usefetch";
import styles from "../../scss/common/ProductCard.module.scss";
import { imageOnError } from "../../common/utils/Functions";
import AppButton from "../../common/theme/Button";

interface IProps {
  reLoadCart?: any;
  setReLoadCart?: (value: boolean) => void;
  product_id: string[];
}
const ShoppingCards = ({ reLoadCart, setReLoadCart, product_id }: IProps) => {
  const params = useParams();

  // const pathname = window.location.href;
  // const [directUrl, setDirectUrl] = useState("");
  const navigate = useNavigate();
  const direct_access_url = DIRECT_ACCESS_URL;
  const [singleProductList, setSingleProductList] = useState([]);

  const [
    loadSingleProductsQuery,
    {
      response: responseSingleProducts,
      // loading: loadingSingleProducts,
      error: errorSingleProducts,
    },
  ] = useFetch(
    `/similiar-store-product/public-list/${direct_access_url}?start=0&limit=4&product_id=${product_id.toString()}`,
    {
      method: "get",
    }
  );

  // useEffect(() => {
  //   if (pathname.includes("localhost")) {
  //     setDirectUrl("store-one");
  //   } else {
  //     const direct_access_url = pathname.split("//")[1].split(".")[0];

  //     setDirectUrl(direct_access_url);
  //   }
  // }, [pathname]);

  const addToCart = (product: any) => {
    navigate("/product-detail/" + product.id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (product_id?.length) {
      loadSingleProductsQuery();
    }
  }, [product_id]);

  useEffect(() => {
    if (
      responseSingleProducts &&
      responseSingleProducts.code === 200 &&
      responseSingleProducts.status === 1
    ) {
      if (responseSingleProducts.data) {
        setSingleProductList(responseSingleProducts.data.list);
      }
    }
  }, [responseSingleProducts, errorSingleProducts]);

  return (
    <section className={styles.travel_package}>
      {singleProductList &&
      singleProductList.filter((item: any) => item.id !== params.id).length >
        0 ? (
        <>
          <div className="heading">
            <h2>Customers Are Also Buying</h2>
            {/*<span>We take pride in providing the best.</span>*/}
          </div>
          <div className={styles.product_row}>
            {singleProductList.length > 0 &&
              singleProductList.map((item: any, index: number) => {
                if (item.id !== params.id)
                  return (
                    <div className={styles.product_lg} key={index}>
                      <div className={styles.product_card}>
                        <div className={styles.card_img}>
                          <img
                            loading="lazy"
                            onError={(event) => imageOnError(event)}
                            src={
                              item.store_product_images &&
                              item.store_product_images.length > 0
                                ? IMAGE_BASE_URL +
                                  item.store_product_images[0].image
                                : "/images/default-product.png"
                            }
                            alt="img"
                          />
                        </div>
                        <div className={styles.card_content}>
                          {item.product_name && (
                            <div className={styles.title}>
                              <h6>{item.product_name}</h6>
                            </div>
                          )}
                          {/* {item.club_price && (
                            <div className={styles.price}>
                             <h5>
                                { item.club_price !== null
                                  ? `$${parseFloat(item.club_price).toFixed(2)}`
                                  : `$${(0).toFixed(2)}`
                                }
                            </h5>
                            </div>
                          )} */}
                          <div>
                            <div className={styles.price}>
                              <h5>
                                {item.club_price === null
                                  ? `$${(0).toFixed(2)}`
                                  : `$${parseFloat(item.club_price).toFixed(
                                      2
                                    )}`}
                              </h5>
                            </div>

                            <AppButton onClick={() => addToCart(item)}>
                              View Detail
                            </AppButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
              })}
          </div>
        </>
      ) : (
        <></>
      )}
    </section>
  );
};

export default ShoppingCards;
