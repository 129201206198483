import { InputField } from "../../common/theme/Input";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { SelectField } from "../../common/theme/select";
import styles from "../../scss/common/common.module.scss";
import Grid from "@mui/material/Grid";
import { RadioInputs } from "../../common/theme/RadioInput";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setCheckoutFormSlice } from "../../redux/CheckoutForm/CheckoutFormSlice";
import AppButton from "../../common/theme/Button";

const phoneRegExp = /^(?=.*?[1-9])[0-9()-. ]+$/;

interface IProps {
  storeDetail?: any;
  showFullname?: any;
  custom_informations?: any;
  selected?: any;
  formInfo?: any;
  first?: any;
  isLoading?: boolean;
  ShowSpecificForm?: any;
  placeOrder: (first: any, second: any, value: any, custom_info: any) => void;
}

const CheckoutFormDetail = ({
  custom_informations,
  selected,
  isLoading,
  ShowSpecificForm,
  placeOrder,
  storeDetail,
}: IProps) => {
  const dispatch = useDispatch();

  const [radioButton] = useState();
  const storeDesignSettingData = useSelector(
    (state: any) => state.storeCheckoutForm.FirstFormSlice
  );
  const [inputErrorId, setInputErrorId] = useState<any>([]);
  const [teamList, setTeamList] = useState<{ value: string; title: string }[]>(
    []
  );

  const [initialValuesData, setInitialValuesData] = useState<any>({
    firstName: storeDesignSettingData.firstName || "",
    lastName: storeDesignSettingData.lastName || "",
    email: storeDesignSettingData.email || "",
    phone: storeDesignSettingData.phone || "",
    teamId: storeDesignSettingData.teamId || "",
  });

  const [
    customerInformationValidationSchema,
    setCustomerInformationValidationSchema,
  ] = useState<any>(
    yup.object().shape({
      firstName: yup.string().when("showFullname", {
        is: 1,
        then: (schema) =>
          schema
            .trim()
            .max(20, "Maximum 20 characters")
            .required("Please enter your first name"),
        otherwise: (schema) => schema,
      }),
      lastName: yup.string().when("showFullname", {
        is: 1,
        then: (schema) =>
          schema
            .trim()
            .max(20, "Maximum 20 characters")
            .required("Please enter your last name"),
        otherwise: (schema) => schema,
      }),
      teamId:
        storeDetail?.is_team_available === 1
          ? yup.string().trim().required("Please select a team")
          : yup.string().trim(),
      email: yup
        .string()
        .trim()
        .email("Invalid email")
        .required("Please enter your email")
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email"),
      phone: yup
        .string()
        .trim()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(7, "Please enter minimum 7 digit  ")
        .max(15, "Maximum 15 characters")
        .required("Please enter your phone number"),
    })
  );

  const handleOnChange = (e: any) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const formik = useFormik({
    initialValues: initialValuesData,
    // validationSchema: {},
    validationSchema: customerInformationValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let keys = Object.keys(values);
      let errorId: any = [];
      let customeSave: any = [];
      custom_informations?.forEach((val: any) => {
        if (!keys.includes(val.id.toString()) && val.is_required === 1) {
          errorId.push(val.id);
        } else {
          customeSave.push({
            id: val.id,
            value: values[val.id],
          });
        }
      });

      if (errorId.length <= 0) {
        dispatch(setCheckoutFormSlice({ ...values, customeSave: customeSave }));
        if (
          ShowSpecificForm?.PaymentForm == false &&
          ShowSpecificForm?.ShippingForm == false
        ) {
          placeOrder(values, null, null, customeSave);
        } else if (ShowSpecificForm?.ShippingForm == true) {
          selected(1);
        } else {
          selected(2);
        }
      } else {
        setInputErrorId(errorId);
      }
    },
  });

  useEffect(() => {
    let schemeObj: any = {};
    let initialObj: any = {};
    custom_informations?.map((item: any) => {
      schemeObj[`${item.id}`] = item?.is_required
        ? yup
            .string()
            .trim()
            .max(50, "Maximum 50 characters")
            .required("This field is required")
        : yup.string().max(50, "Maximum 50 characters");
      initialObj[`${item.id}`] = storeDesignSettingData[item.id] ?? "";
    });
    schemeObj["firstName"] = yup
      .string()
      .trim()
      .required("Please enter your first name")
      .max(20, "Maximum 20 characters");
    schemeObj["lastName"] = yup
      .string()
      .trim()
      .required("Please enter your last name")
      .max(20, "Maximum 20 characters");
    schemeObj["email"] = yup
      .string()
      .trim()
      .email("Invalid email")
      .required("Please enter your email")
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid email");
    schemeObj["phone"] = yup
      .string()
      .trim()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(7, "Please enter minimum 7 digit  ")
      .max(15, "Maximum 15 characters")
      .required("Please enter your phone number");
    schemeObj["teamId"] =
      storeDetail?.is_team_available === 1
        ? yup.string().trim().required("Please select a team")
        : yup.string().trim();
    initialObj[`firstName`] = storeDesignSettingData.firstName || "";
    initialObj[`lastName`] = storeDesignSettingData.lastName || "";
    initialObj[`email`] = storeDesignSettingData.email || "";
    initialObj[`phone`] = storeDesignSettingData.phone || "";
    initialObj[`teamId`] = storeDesignSettingData.teamId || "";

    setCustomerInformationValidationSchema(yup.object().shape(schemeObj));
    setInitialValuesData(initialObj);
  }, [custom_informations]);

  useEffect(() => {
    const teams = storeDetail?.teams?.map((item: any) => {
      return {
        title: item.team_name,
        value: item.id.toString(),
      };
    });
    setTeamList(teams);
  }, [storeDetail]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} className={styles.CheckoutCart}>
          <Grid item lg={6} xs={12}>
            <InputField
              formik={formik}
              label="Player First Name"
              placeholder="Enter player first name"
              name="firstName"
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              formik={formik}
              label="Player Last Name"
              placeholder="Enter player last name"
              name="lastName"
              // showFullname
              required
            />
          </Grid>
          {storeDetail?.is_team_available === 1 ? (
            <Grid item lg={12} xs={12}>
              <SelectField
                label={"Select Team"}
                name="teamId"
                placeholder="Select"
                formik={formik}
                options={teamList ?? []}
                required
              />
            </Grid>
          ) : (
            ""
          )}

          <Grid item lg={6} xs={12}>
            <InputField
              formik={formik}
              label="Email"
              placeholder="Enter email"
              name="email"
              // disabled={true}
              required
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <InputField
              formik={formik}
              type="string"
              label="Phone"
              placeholder="Enter your Phone"
              name="phone"
              required
            />
          </Grid>

          {custom_informations &&
            custom_informations.map((val: any, index: number) => {
              if (val.input_type === 1) {
                // const value = storeDesignSettingData.find((data:any)=>data[])
                return (
                  <Grid item lg={6} xs={12} key={index}>
                    <SelectField
                      label={val?.question_detail?.question ?? val.question}
                      // name={`dynamic${val.id}`}
                      //value={storeDesignSettingData[val.id]}
                      name={`${val.id}`}
                      placeholder="Select"
                      formik={formik}
                      options={val.custom_information_options.map(
                        (val: any) => {
                          return { title: val.name, value: val.name };
                        }
                      )}
                      required={val.is_required === 1 ? true : false}
                    />
                    {val.is_required === 1 && inputErrorId.includes(val.id) ? (
                      <span className={styles.errorMsg}>
                        Please select{" "}
                        {val?.question_detail?.question ?? val.question}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                );
              } else if (val.input_type === 2) {
                return (
                  <Grid item lg={6} xs={12} key={index}>
                    <InputField
                      formik={formik}
                      type="text"
                      label={val?.question_detail?.question ?? val.question}
                      placeholder=""
                      name={`${val.id}`}
                      required={val.is_required === 1 ? true : false}
                    />
                  </Grid>
                );
              } else if (val.input_type == 3) {
                return (
                  <Grid item lg={6} xs={12} key={index}>
                    <InputField
                      valueData={storeDesignSettingData[val.id]}
                      // required={val.question_detail.is_required ? true : true}
                      formik={formik}
                      label={val?.question_detail?.question ?? val.question}
                      name={`${val.id}`}
                      textFieldProps={{ multiline: true, rows: 3 }}
                      required={val.is_required ? true : false}
                    />
                    {val.is_required === 1 && inputErrorId === val.id ? (
                      <span className={styles.errorMsg}>
                        Please enter{" "}
                        {val?.question_detail?.question ?? val.question}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                );
              } else if (val.input_type == 4) {
                return (
                  <Grid item lg={6} xs={12} key={index}>
                    <RadioInputs
                      formik={formik}
                      label={val?.question_detail?.question ?? val.question}
                      name={`${val.id}`}
                      onChange={handleOnChange}
                      value={radioButton}
                      options={[
                        { label: "Yes", value: "1" },
                        { label: "No", value: "0" },
                      ]}
                      required={val.is_required ? true : false}
                    />
                    {val.is_required === 1 && inputErrorId === val.id ? (
                      <span className={styles.errorMsg}>
                        Please select{" "}
                        {val?.question_detail?.question ?? val.question}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                );
              }
            })}
        </Grid>

        <div className={styles.shoppingChat_checkoutBtn}>
          {(ShowSpecificForm?.PaymentForm == true ||
            ShowSpecificForm?.ShippingForm == true) && (
            <AppButton
              type="submit"
              className={styles.continue}
              // onClick={GoToNextPage}
            >
              Save & Continue
            </AppButton>
          )}

          {ShowSpecificForm?.PaymentForm == false &&
            ShowSpecificForm?.ShippingForm == false && (
              <AppButton
                type="submit"
                className={styles.continue}
                // onClick={placeOrder}
                disabled={isLoading}
              >
                <span style={{ display: "flex", justifyContent: "center" }}>
                  Place Order
                </span>
              </AppButton>
            )}
        </div>
      </form>
    </>
  );
};

export default CheckoutFormDetail;
