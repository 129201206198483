import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { MultiSelect } from "../../common/theme/multiSelect";
import { DIRECT_ACCESS_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import {
  setClearSearch,
  setFilterLoader,
  setSearch,
  setSearchType,
  setStoreTeamFilterSlice,
} from "../../redux/StoreDesignList/StoreDesignListSlice";
import styles from "../../scss/pageComponent/home.module.scss";
import AppContainer from "../../common/theme/Container";
import AppButton from "../../common/theme/Button";

const TeamPackageSection = () => {
  const FilterLoader = useSelector(
    (state: any) => state.storeDesignList.filterLoader
  );
  const storeDesignSettingData = useSelector(
    (state: any) => state.storeDesignList
  );
  const dispatch = useDispatch();
  const direct_access_url = DIRECT_ACCESS_URL;
  const [data, setData] = useState<any>({});
  const [selectedValues, setSelectedValues] = useState<any>([]);

  const [teamFilterList, setTeamFilterList] = useState<any>([]);
  const [getTeamList, { response: TeamListRes, error: TeamListError }] =
    useFetch(`/get-store-team/public-list/${direct_access_url}`, {
      method: "get",
    });
  const formik = useFormik({
    initialValues: {
      team: "",
    },
    // validationSchema: {},
    onSubmit: (values) => {},
  });

  const filterBundleData = () => {
    if (selectedValues?.length > 0) {
      dispatch(setSearch(true));
      dispatch(setFilterLoader(true));
      dispatch(setSearchType(true));
      dispatch(setStoreTeamFilterSlice(selectedValues));
    }
  };
  const handleClose = () => {
    setSelectedValues([]);
    dispatch(setSearch(true));
    // dispatch(setFilterLoader(true));
    dispatch(setStoreTeamFilterSlice([]));
  };
  useEffect(() => {
    if (storeDesignSettingData) {
      setData(storeDesignSettingData?.data);
    }
  }, [storeDesignSettingData]);
  useEffect(() => {
    getTeamList();
  }, []);

  useEffect(() => {
    if (TeamListRes && TeamListRes?.status) {
      const options = TeamListRes?.data?.list?.map((data: any) => ({
        title: data.team_name,
        value: String(data.id),
        org_id: data.org_id,
      }));
      setTeamFilterList(options);
    }
  }, [TeamListRes]);

  useEffect(() => {
    if (selectedValues?.length == 0) {
      dispatch(setClearSearch(true));
      dispatch(setSearch(true));
    }
  }, [selectedValues]);

  return (
    <section className={styles.team_section}>
      <AppContainer>
        <div className={styles.team_box}>
          <div className="heading">
            <h2>
              {data?.team_filter_title
                ? data.team_filter_title
                : "Find Your Team Package"}
            </h2>
          </div>
          <div className={styles.teamCustomForm}>
            <MultiSelect
              formik={formik}
              label="Team"
              name="team"
              value={selectedValues}
              limitTags={4}
              placeholder="Select team"
              options={teamFilterList}
              setSelectedValue={setSelectedValues}
              handleClose={handleClose}
            />
            <div>
              <AppButton
                className="Custom_button Search_btn"
                onClick={filterBundleData}
              >
                {FilterLoader && (
                  <span className="loadingBars">
                    <RotatingLines
                      strokeColor="currentColor"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                    />
                  </span>
                )}
                Search
              </AppButton>
            </div>
          </div>
          <p className={styles.note}>
            {data?.team_filter_description
              ? data?.team_filter_description
              : "IMPORTANT: Please make sure to find the correct team, as every team package requires different items (in most cases)"}
          </p>
        </div>
      </AppContainer>
    </section>
  );
};

export default TeamPackageSection;
